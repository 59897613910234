import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import type { DateTimeRowProps } from './FormRows'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0px auto',
    },
    picker: {
      minWidth: '150px',
      width: 'calc(50% - 20px)'
    },
  }),
)

type Props = Omit<DateTimeRowProps, 'type'> & {
  onChange?: (name: string, date: Date | null) => void
  className?: string,
  error?: boolean,
  helperText?: string,
}


const DateTimeRow: React.FC<Props> = ({ value, onChange, name, className, label }) => {
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = useState<Date | null>(value ?? null)

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    onChange?.(name, date)
  }

  return (
    <div className={clsx(classes.root, className)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          format="yyyy/MM/dd HH:mm"
          ampm={false}
          margin="dense"
          clearable
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DateTimeRow
