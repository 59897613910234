import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDialogStyles } from './styles'

type Props = {
  title: string
  body: string | JSX.Element
  cleanup: () => void
  resolve: () => void
}

const AlertModal: React.FC<Props> = (props) => {
  const { title, body } = props
  const classes = useDialogStyles()
  const [open, setOpen] = useState(true)

  const handleModalClose = () => {
    setOpen(false)
  }

  const confirm = () => {
    props.resolve()
    props.cleanup()
  }

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.body}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={confirm}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * 非同期アラートダイアログ表示
 * @param title ダイアログタイトル
 * @param body 内容
 * @returns 
 */
export const alert = (title: string, body: string | JSX.Element): Promise<void> => {
  const wrapper = document.body.appendChild(document.createElement('div'))
  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper)
    return setTimeout(() => wrapper.remove())
  }
  const promise = new Promise<void>((resolve, reject) => {
    try {
      ReactDOM.render(
        <AlertModal
          cleanup={cleanup}
          resolve={resolve}
          title={title}
          body={body}
        />,
        wrapper,
      )
    } catch (e) {
      cleanup()
      reject(e)
      throw e
    }
  })
  return promise
}
