import React, { useEffect, useState } from 'react'

import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LeftDrawer from './components/LeftDrawer'

import { menu } from './routes'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { Menu as MenuIcon } from '@material-ui/icons'
import { smWidth } from './app/constants'
import { selectCurrentRoleType } from './features/signin/signinSlice'

const drawerWidth = '300px'

const useStyles = makeStyles((theme) =>
  ({
    root: {
      height: '100vh',
      display: 'grid',
      gridTemplate:
        `"drawer header" auto
         "drawer main" 1fr
         "drawer footer" auto / ${drawerWidth} 1fr`,
    },
    drawer: {
      gridArea: 'drawer',
    },
    permanentDrawerPaper: {
      width: drawerWidth,
      backgroundColor: '#EEE !important',
    },
    temporaryDrawerPaper: {
      width: drawerWidth,
      backgroundColor: '#EEE !important',
    },
    appBar: {
      gridArea: 'header',
    },
    mainContainer: {
      gridArea: 'main',
      margin: 'auto',
    },
    footer: {
      gridArea: 'footer',
      bottom: '0px',
    },
    footerToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuIcon: {
      display: 'none',
    },
    [`@media (max-width: ${smWidth}px)`]: {
      root: {
        // height: 'auto',
        display: 'grid',
        gridTemplate: 
          `"header" auto
           "main" 1fr
           "footer" auto / auto`,
      },
      // permanentDrawer: {
      //   display: 'none',
      // },
      permanentDrawerPaper: {
        display: 'none',
      },
      menuIcon: {
        display: 'block',
      },
    },
  })
)

const Main: React.FC = ()  => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const role = useAppSelector(selectCurrentRoleType)
  const [openDrawer, setOpenDrawer] = useState(+window.outerWidth < smWidth && !menu.some(m => m.path === window.location.pathname))

  useEffect(() => {
    // 一括で色々取得
    if ( !role ) return
    menu.forEach(m => {
      if(m.acceptRoles?.includes(role) && m.fetchers) {
        for(const fetcher of m.fetchers) {
          dispatch(fetcher())
        }
      }
    })
  }, [dispatch, role])

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer)
  }
  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  return (
    <div className={classes.root}>
      <Router>
        <LeftDrawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.permanentDrawerPaper }}
        />
        <LeftDrawer
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={handleCloseDrawer}
          className={classes.drawer}
          classes={{ paper: classes.temporaryDrawerPaper }}
        />

        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuIcon}
            >
              <MenuIcon />
            </IconButton>
            {menu.map(({ path, title }, i) => (
              <Route path={path} key={path}>
                <Typography variant="h4">{title}</Typography>
              </Route>
            ))}
          </Toolbar>
        </AppBar>
        <Container maxWidth="xl" className={classes.mainContainer}>
          {menu.map(({ path, component }) => (
            <Route path={path} component={component} key={path} />
          ))}
        </Container>
        <AppBar position="static" color="default" className={classes.footer}>
          <Toolbar variant="dense" className={classes.footerToolbar}>
            <Typography color="textSecondary">
              © Copyright CSP 2021
            </Typography>
          </Toolbar>
        </AppBar>
      </Router>
    </div>
  )
}

export default Main
