import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { CreateMissionRequest, MissionsResponse, UpdateMissionRequest } from './missionsTypes'

export async function fetchMissions(): Promise<MissionsResponse | undefined> {
  const res = await api.get('/maintenance/mission-set/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function createMission(mission: CreateMissionRequest, robotId: string, tenantId: string) {
  await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/mission-set-create`, {
    body: kebabizedStringifier(mission)
  })
}

export async function updateMission(mission: UpdateMissionRequest, robotId: string, tenantId: string) {
  const missionId = mission.id
  delete mission.id
  await api.put(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/mission-set/${missionId}`, {
    body: kebabizedStringifier(mission)
  })
}

export async function deleteMission(missionId: string, robotId: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/mission-set/${missionId}`)
}
