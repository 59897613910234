import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { ErrorLog } from './errorsTypes'
import { useAppSelector } from '../../app/hooks'
import { selectRobots } from '../robots/robotsSlice'

type Props = {
  error: ErrorLog
  className?: string
  style?: CSSProperties
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  cardBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    flexGrow: 1,
  },
  cardHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  cardTitle: {
    flexShrink: 0,
    flexGrow: 1,
  },
  cardStatus: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '3px 0px',
  },
  type: {
    backgroundColor: '#EEE',
    margin: '5px 0px',
    padding: '5px 10px',
    borderRadius: '100vw',
  },
}))

export const ErrorCard: React.FC<Props> = ({ error, className, style }) => {
  const classes = useStyles()
  const robots = useAppSelector(selectRobots)

  return (
    <Card className={clsx(classes.card, className)} style={style}>
      <Box className={classes.cardBox}>
        <CardContent className={classes.boxContainer}>
          <div className={classes.cardHeader}>
            <Typography variant="h5" className={classes.cardTitle}>
              {new Date(error.createAt).toLocaleString()}
            </Typography>
            <div className={classes.cardStatus}>
              <Typography variant="body2" align="right">
                {robots.find(r => r.id === error.robotId)?.name ?? error.robotId}
              </Typography>
              {/* <span style={{ width: 10 }}></span>
              <span className={classes.type}>{error.type}</span> */}
            </div>
          </div>
          <Typography variant="body1">{ error.code }</Typography>
          <Typography variant="body1">{ error.message } {
            error.status != null && error.status.length !== 0 && `(${ error.status })`
          }</Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
