import React, { createRef, useEffect, useState } from 'react'
import { TextField, makeStyles, createStyles, Button } from '@material-ui/core'
import type { FormFileRowProps } from './FormRows'
import ReactCrop, { Crop } from 'react-image-crop'
import * as faceapi from 'face-api.js'

import 'react-image-crop/dist/ReactCrop.css'
import clsx from 'clsx'

const WeightsUrl = "/weights"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    fileInput: {
      display: 'none',
    },
    fileNameRoot: {
      backgroundColor: '#F8F8F8',
    },
    fileButton: {
      margin: '8px 0px 5px 4px',
      height: '40px',
    },
    crop: {
      maxWidth: '100%'
    },
    cropHelper: {
      color: 'red',
      margin: '5px 0px',
    },
  }),
)

type Props = Omit<FormFileRowProps, 'type'> & {
  onChange?: (name: string, file: Blob | null) => void
  className?: string
  error?: boolean
  helperText?: string
}

const ImageRow: React.FC<Props> = ({ value, accept, onChange, name, className, label, error, helperText }) => {
  const classes = useStyles()
  const fileInputRef = createRef<HTMLInputElement>()

  const [fileName, setFileName] = useState(value ?? '')
  const [cropRect, setCropRect] = useState<Partial<Crop>>({ unit: '%' });
  const [cropHelper, setCropHelper] = useState('')
  
  const [imgSrc, setImgSrc] = useState<string>('')
  const [img, setImg] = useState<HTMLImageElement | null>(null)
  const [croppedImage, setCroppedImage] = useState('')
  
  useEffect(() => {
    Promise.all([
      faceapi.loadSsdMobilenetv1Model(WeightsUrl),
      faceapi.loadFaceLandmarkModel(WeightsUrl),
      faceapi.loadFaceRecognitionModel(WeightsUrl),
    ])
  }, [])

  const handleFileSelection = () => {
    fileInputRef.current?.click()
  }

  const crop = (x: number, y: number, w: number, h: number, _img: HTMLImageElement | undefined = img ?? undefined) => {
    console.log(x, y, w, h)
    const cropCanv = document.createElement('canvas')
    
    cropCanv.width = w
    cropCanv.height = h
    
    const ctx = cropCanv.getContext('2d')
    if(ctx && _img) {
      ctx.drawImage(_img, x, y, w, h, 0, 0, w, h)
      setCroppedImage(cropCanv.toDataURL('image/jpeg', 0.95))
      cropCanv.toBlob((blob) => {
        if(!blob) return
        onChange?.(name, blob)
        setCropHelper('')
      }, 'image/jpeg', 0.95)
    }
    cropCanv.remove()
  }

  const handleFileChanged: React.ChangeEventHandler<HTMLInputElement> = (
    ev,
  ) => {
    const file = ev.target.files?.[0]
    if (
      file &&
      (accept == null ||
        file.type === accept ||
        (accept.endsWith('*') &&
          file.type.startsWith(accept.slice(0, -1))))
    ) {
      setFileName(file.name ?? '')
      const reader = new FileReader()
      setCropRect({ unit: '%' })
      reader.addEventListener('load', () => {
        setImgSrc(reader.result as string)
        const img = new Image()
        img.src = reader.result as string
        img.addEventListener('load', async () => {
          setImg(img)

          const res = await faceapi.detectAllFaces(img).run()
          const face = res.reduce((p, c) => {
            if(!p) return c
            const ca = c.box.height * c.box.width
            const pa = p.box.height * p.box.width
            return pa < ca ? c : p
          }, null as null | faceapi.FaceDetection)
          if (face) {
            const iW = img.naturalWidth * 0.01
            const iH = img.naturalHeight * 0.01
            const { x, y, width, height } = face.box
            setCropRect({
              ...cropRect,
              x: x / iW,
              y: y / iH,
              width: width / iW,
              height: height / iH,
            })
            crop(x, y, width, height, img)
          } else {
            setCropHelper('顔の範囲を選択してください')
          }
        })
      })
      reader.readAsDataURL(file)
    }
  }
  
  const handleCropChange = (_: Crop, c: Crop) => {
    setCropRect(c)
    if(c.width === 0 && c.height === 0) {
      setCropHelper('顔の範囲を選択してください')
      setCroppedImage('')
      onChange?.(name, null)
    }
  }
  
  const handleCropComplete = () => {
    const c = cropRect
    if(img) {
      const iW = img.naturalWidth / 100
      const iH = img.naturalHeight / 100
      if(c.x && c.y && c.width && c.height)
        crop(c.x * iW, c.y * iH, c.width * iW, c.height * iH)
    }
  }
  
  return (
    <div className={clsx(classes.root, className)}>
      <TextField
        inputProps={{ readOnly: true }}
        variant="outlined"
        onDoubleClick={handleFileSelection}
        label={label}
        value={fileName}
        error={error}
        helperText={helperText}
        margin="dense"
        classes={{
          root: classes.fileNameRoot,
        }}
        />
      <Button
        variant="contained"
        classes={{ root: classes.fileButton }}
        onClick={handleFileSelection}
        disableElevation
        >
        ファイルを選択
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        className={classes.fileInput}
        onChange={handleFileChanged}
        accept={accept}
        />
      <h5 className={classes.cropHelper}>{cropHelper}</h5>
      <ReactCrop
        src={imgSrc}
        crop={cropRect}
        className={classes.crop}
        onChange={handleCropChange} 
        onComplete={handleCropComplete}
        /><br />
      { croppedImage.length > 0 && <img src={croppedImage} alt="croppedImage" style={{ maxHeight: '200px', maxWidth: '200px' }}/>}
    </div>
  )
}

export default ImageRow
