import React, { useState } from 'react'
// import { createStyles, makeStyles } from '@material-ui/core'
import { useAppSelector } from '../app/hooks'
import {
  selectTenantsForSelect,
  selectTenantsStatus,
} from '../features/tenants/tenantsSlice'
import Form from '../components/Form'
import {
  selectRobots,
  selectRobotsStatus,
} from '../features/robots/robotsSlice'
import { Container } from '@material-ui/core'
import {
  eventTags,
  eventKinds,
  eventStatuses,
  EventsSearchOptions,
  eventSubKinds,
  guardEventSubKinds,
  GuardEventSubKind,
} from '../features/events/eventsTypes'
import { EventList } from '../features/events/EventList'

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     actionsContainer: {
//       width: '100%',
//       display: 'flex',
//       justifyContent: 'flex-end',
//       marginBottom: '10px',
//     },
//     action: {
//       margin: '10px',
//     },
//   }),
// )

const EventsPage: React.FC = () => {
  // const classes = useStyles()
  // const [openCreateModal, setOpenCreateModal] = useState(false)
  const tenants = useAppSelector(selectTenantsForSelect)
  const tenantsState = useAppSelector(selectTenantsStatus)
  const robots = useAppSelector(selectRobots)
  const robotsState = useAppSelector(selectRobotsStatus)

  const [showResult, setShowResult] = useState(false)
  const [searchOptions, setSearchOptions] = useState({} as EventsSearchOptions)

  const onGoBack = () => {
    setShowResult(false)
  }

  return (tenantsState === 'loaded' || tenantsState === 'unload') &&
    robotsState === 'loaded' ? (
    !showResult ? (
      <Container maxWidth="sm">
        <Form
          formTitle="検索条件"
          FormRows={[
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
              value: searchOptions.tenantId,
            },
            {
              type: 'select',
              name: 'robotId',
              label: 'ロボット',
              required: false,
              multiple: true,
              items: robots,
              value: searchOptions.robotId,
              itemGetter(item) {
                return {
                  name: item.name,
                  value: item.id,
                }
              },
              filter(values, items) {
                return items.filter((i) => i.tenantId === values['tenantId'])
              },
            },
            {
              type: 'datetime',
              name: 'startDatetime',
              label: '検索開始日時',
              required: false,
              value:
                searchOptions.startDate ??
                (() => {
                  const date = new Date()
                  date.setDate(date.getDate() - 7)
                  date.setHours(0)
                  date.setMinutes(0)
                  date.setSeconds(0)
                  return date
                })(),
            },
            {
              type: 'datetime',
              name: 'endDatetime',
              label: '検索終了日時',
              required: false,
              value:
                searchOptions.endDate ??
                (() => {
                  const date = new Date()
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  return date
                })(),
            },
            {
              type: 'select',
              name: 'tag',
              label: 'タグ',
              required: false,
              multiple: true,
              items: eventTags,
              value: searchOptions.tag,
            },
            {
              type: 'select',
              name: 'kind',
              label: 'イベント種別',
              multiple: true,
              required: false,
              items: eventKinds,
              value: searchOptions.eventKind,
            },
            {
              type: 'select',
              name: 'subKind',
              label: 'イベント詳細種別',
              multiple: true,
              required: false,
              items: eventSubKinds,
              value: searchOptions.eventSubKind,
              hidden(values) {
                const kinds =
                  values['kind'] instanceof Array ? values['kind'] : []
                const hidden =
                  !kinds.includes('object') && !kinds.includes('action')
                if (
                  hidden &&
                  searchOptions.eventSubKind &&
                  searchOptions.eventSubKind.length > 0
                ) {
                  setSearchOptions({ ...searchOptions, eventSubKind: [] })
                }
                return hidden
              },
              filter(values, items) {
                return items.filter((i) =>
                  (values['kind'] as string[]).includes(i.subOf),
                )
              },
            },
            {
              type: 'select',
              name: 'status',
              label: 'イベント対応状況',
              multiple: true,
              required: false,
              items: eventStatuses,
              value: searchOptions.status,
            },
          ]}
          createText="検索"
          onSubmit={(values) => {
            const eventKind =
              values['kind'] instanceof Array ? values['kind'] : []
            const options = {
              tenantId: values['tenantId'] as string,
              robotId:
                values['robotId'] instanceof Array ? values['robotId'] : [],
              startDate: values['startDatetime'] as Date,
              endDate: values['endDatetime'] as Date,
              tag: values['tag'] instanceof Array ? values['tag'] : [],
              eventKind: eventKind,
              eventSubKind:
                values['subKind'] instanceof Array
                  ? values['subKind'].filter((sk) =>
                      eventKind.includes(
                        guardEventSubKinds[sk as GuardEventSubKind].subOf,
                      ),
                    )
                  : [],
              status: values['status'] instanceof Array ? values['status'] : [],
            } as EventsSearchOptions
            if (values['tenantId']) {
              setSearchOptions(options)
            }
            setShowResult(true)
          }}
        />
      </Container>
    ) : (
      <EventList goBack={onGoBack} searchOptions={searchOptions} />
    )
  ) : null
}

export default EventsPage
