import { api } from '../Api'
import { ErrorResponse } from '../commonTypes'
import { alert } from '../dialogs/alert'
import { loading } from '../dialogs/loading'
import { apiEndpoint, camelizedParser, kebabize } from '../utils'
import {
  EventExportOptions,
  EventsExportResponse,
  EventsResponse,
  EventsSearchOptions,
  GuardEvent,
} from './eventsTypes'

const queryInjector = (url: URL, options: EventsSearchOptions): URL => {
  Object.entries(options).forEach(([k,v]) => {
    if(v == null || v === '' || k === 'tenantId') return
    if (v instanceof Date) {
      url.searchParams.append(kebabize(k), Math.floor(v.getTime() / 1000) + '')
    } else if (v instanceof Array) {
      v.forEach(q => {
        url.searchParams.append(kebabize(k), q + '')
      })
    } else {
      url.searchParams.append(kebabize(k), v + '')
    }
  })
  return url
}

export async function exportEvents(options: EventsSearchOptions): Promise<void> {
  const url = new window.URL(`/maintenance/tenant/${options.tenantId}/admin/event/export`, apiEndpoint)
  api.download(queryInjector(url, options).toString())
}

export async function fetchEvents(options: EventsSearchOptions): Promise<GuardEvent[]> {
  const url = new window.URL(`/maintenance/tenant/${options.tenantId}/admin/event-list`, apiEndpoint)
  queryInjector(url, options)
  const res = await api.get(url.pathname + url.search)
  const json = camelizedParser<EventsResponse>(await res.text())

  return json.data ?? []
}

export async function exportEventsNext(searchOptions: EventsSearchOptions, exportOptions: EventExportOptions) {
  const url = new window.URL(`/maintenance/tenant/${searchOptions.tenantId}/admin/event/export`, apiEndpoint)
  const closeLoading = loading(
    'イベント履歴エクスポート',
    'エクスポートのためにzipファイルを作成しています。\nこれには時間がかかる場合があります。',
  )
  queryInjector(url, {...searchOptions, ...exportOptions})
  const res = await api.get(url.pathname + url.search)
  closeLoading()
  if(res.ok) {
    const json = camelizedParser<EventsExportResponse>(await res.text())
    if (json.data.downloadUrl) {
      api.openDownloadLink(json.data.downloadUrl)
    } else {
      alert('エクスポート失敗', 'エクスポートするデータが見つかりませんでした。')
    }
  } else {
    alert('エクスポート失敗', camelizedParser<ErrorResponse>(await res.text()).error.message)
  }
  // api.openDownloadLink('https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-zip-file.zip')
  // api.download(queryInjector(url, {...searchOptions, ...exportOptions}).toString())
}
