
export const logGroups = [
  'http-server',
  'sound-server',
  'ns-power',
  'ns-restarter',
  'live-streamer'
]

export type LogsRequestOptions = {
  group: typeof logGroups[number]
  robotId: string
  startDate?: number | null
  endDate?: number | null
  limit?: number | null
  filterPattern?: string | null
  nextToken?: string | null
}

export type LogsSearchParams = {
  group: typeof logGroups[number]
  tenantId: string
  robotId: string
  startDate?: Date | null
  endDate?: Date | null
  limit?: number | null
  filterPattern?: string | null
}

export const LogsSearchConv = (params: LogsSearchParams, nextToken: string|null = null): LogsRequestOptions => {
  const options: LogsRequestOptions = {
    group: params.group,
    robotId: params.robotId,
    limit: params.limit,
    filterPattern: params.filterPattern,
  }
  if (nextToken) {
    options.nextToken = nextToken
  }
  if (params.startDate) options.startDate = Math.floor(params.startDate.getTime() / 1000)
  if (params.endDate) options.endDate = Math.floor(params.endDate.getTime() / 1000)
  return options
}

export type LogsExportOptions = Omit<LogsRequestOptions, 'nextToken'>

export type Log = {
  timestamp: number
  message: string
}

export type LogsData = {
  data: Log[]
  nextToken: string
}

export type LogsResponse = {
  status: string
  data: LogsData
}
