import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createMission, deleteMission, fetchMissions, updateMission } from './missionsApi'
import type { CreateMissionRequest, Mission } from './missionsTypes'


export interface missionsState {
  missions: Mission[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: missionsState = {
  missions: [],
  status: 'unload',
};

export const fetchMissionsAsync = createAsyncThunk(
  'missions/fetch',
  async () => {
    const response = await fetchMissions().catch((err) => {
      console.log('missions fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const createMissionAsync = createAsyncThunk(
  'missions/create',
  async ([mission, robotId, tenantId]: [CreateMissionRequest, string, string]) => {
    await createMission(mission, robotId, tenantId)
    return (await fetchMissions())?.data
  }
)

export const updateMissionAsync = createAsyncThunk(
  'missions/update',
  async ([mission, robotId, tenantId]: [CreateMissionRequest, string, string]) => {
    await updateMission(mission, robotId, tenantId)
    return (await fetchMissions())?.data
  }
)

export const deleteMissionAsync = createAsyncThunk(
  'missions/delete',
  async ([missionId, robotId, tenantId]: [string, string, string]) => {
    await deleteMission(missionId, robotId, tenantId)
    return (await fetchMissions())?.data
  }
)

export const missionsSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMissionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMissionsAsync.fulfilled, (state: missionsState, action) => {
        state.status = 'loaded';
        state.missions = action.payload
      })
      .addCase(createMissionAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createMissionAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.missions = action.payload ?? state.missions
      })
      .addCase(updateMissionAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateMissionAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.missions = action.payload ?? state.missions
      })
      .addCase(deleteMissionAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteMissionAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.missions = action.payload ?? state.missions
      })
  },
});


export const selectMissions = (state: RootState) => state.missions.missions;
export const selectMissionsStatus = (state: RootState) => state.missions.status
export const selectMissionsForSelect = (state: RootState) => state.missions.missions.map(({id: value, name}) => ({value, name}))

export default missionsSlice.reducer;
