// import React, { Component, useState } from 'react'

import { useSelector } from 'react-redux'

import { selectSigninStatus } from './features/signin/signinSlice'
import LoginPage from './pages/Login'
import Main from './Main'

function App() {
  const signin = useSelector(selectSigninStatus)

  return <>{signin === 'signin' ? <Main /> : <LoginPage />}</>
}

export default App
