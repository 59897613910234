import {
  Container,
} from '@material-ui/core'
import React from 'react'
import { useAppSelector } from '../../app/hooks'
import Form from '../../components/Form'
import { selectRobots, selectRobotsStatus } from '../robots/robotsSlice'
import { selectTenantsForSelect, selectTenantsStatus } from '../tenants/tenantsSlice'
import { exportLogs } from './logsApi'
import {
  logGroups,
  LogsSearchConv,
  LogsSearchParams,
} from './logsTypes'

type Props = {
  onSubmit: (options: LogsSearchParams) => void
  searchParams?: LogsSearchParams | null
}

export const LogSearch: React.FC<Props> = (props) => {
  const param = props.searchParams ?? ({} as LogsSearchParams)
  const tenants = useAppSelector(selectTenantsForSelect)
  const tenantsState = useAppSelector(selectTenantsStatus)
  const robots = useAppSelector(selectRobots)
  const robotsState = useAppSelector(selectRobotsStatus)
  
  return (tenantsState === 'loaded' || tenantsState === 'unload') &&
    robotsState === 'loaded' ? (
    <Container maxWidth="sm">
      <Form
        FormRows={[
          {
            type: 'select',
            name: 'group',
            label: 'ログ種類',
            required: true,
            multiple: false,
            items: logGroups,
            value: param.group,
          },
          {
            type: 'select',
            name: 'tenantId',
            label: 'テナント',
            items: tenants,
            value: param.tenantId,
          },
          {
            type: 'select',
            name: 'robotId',
            label: 'ロボット名',
            items: robots,
            value: param.robotId,
            itemGetter(item) {
              return {
                name: item.name,
                value: item.id,
              }
            },
            filter(values, items) {
              return items.filter((i) => i.tenantId === values['tenantId'])
            },
          },
          {
            type: 'datetime',
            name: 'startDate',
            label: '検索開始日時',
            value: param.startDate ?? undefined,
          },
          {
            type: 'datetime',
            name: 'endDate',
            label: '検索終了日時',
            value: param.endDate ?? undefined,
          },
          {
            name: 'filterPattern',
            label: '検索文字列',
            required: false,
            value: param.filterPattern ?? undefined,
          },
          // {
          //   type: 'number',
          //   name: 'limit',
          //   label: '検索対象数',
          //   placeholder: '100',
          //   required: false,
          //   value: param.limit ?? 100,
          // }
        ]}
        createText="検索"
        onSubmit={(f) => {
          props.onSubmit({
            group: f['group'] as string,
            startDate: f['startDate'] as Date,
            endDate: f['endDate'] as Date,
            filterPattern: f['filterPattern'] as string,
            // limit: Number(f['limit'] as string),
            tenantId: f['tenantId'] as string,
            robotId: f['robotId'] as string,
           })
        }}
        onExport={(f) => {
          exportLogs(
            LogsSearchConv({
              group: f['group'] as string,
              startDate: f['startDate'] as Date,
              endDate: f['endDate'] as Date,
              filterPattern: f['filterPattern'] as string,
              // limit: Number(f['limit'] as string),
              tenantId: f['tenantId'] as string,
              robotId: f['robotId'] as string,
            }),
            )
          }}
          />
    </Container>
  ) : null
}
