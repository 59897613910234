import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDialogStyles } from './styles'

type Props = {
  title: string
  body: string
  noCancel: boolean
  cleanup: () => void
}

const LoadingModal: React.FC<Props> = (props) => {
  const { title, body } = props
  const classes = useDialogStyles()
  const [open, setOpen] = useState(true)

  const handleModalClose = () => {
    if (!props.noCancel) {
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleModalClose}>
      {
        title !== '' && 
          <DialogTitle>
            {title}
          </DialogTitle>
      }
      <DialogContent className={classes.centering} >
        <CircularProgress className={classes.progress} />
        <DialogContentText className={classes.body}>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

/**
 * Loadingダイアログ表示
 * @param title 読み込み中タイトル
 * @param body 読み込み内容
 * @returns 読み込み表示終了関数
 */
export const loading = (title: string, body: string, noCancel: boolean = false): (() => void) => {
  const wrapper = document.body.appendChild(document.createElement('div'))
  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper)
    return setTimeout(() => wrapper.remove())
  }
  try {
    ReactDOM.render(
      <LoadingModal
        cleanup={cleanup}
        title={title}
        noCancel={noCancel}
        body={body}
      />,
      wrapper,
    )
  } catch (e) {
    cleanup()
    throw e
  }
  return cleanup
}
