import TenantPage from './pages/Tenant'
import RobotPage from './pages/Robot'
import UserPage from './pages/User'
import DevicePage from './pages/Device'
import FloorPage from './pages/Floor'
import MissionPage from './pages/Mission'
import SoundPage from './pages/Sound'
import CompositionPage from './pages/Composition'
import SchedulePage from './pages/Schedule'
import React from 'react'
import { RoleType } from './features/users/usersTypes'
import MapsPage from './pages/Maps'
import LogsPage from './pages/Logs'
import EventsPage from './pages/Events'
import ErrorsPage from './pages/Errors'
import OperationsPage from './pages/Controls'
import FacePage from './pages/Face'
import PresetsPage from './pages/Presets'

import { AsyncThunk } from '@reduxjs/toolkit'
import { fetchTenantAsync } from './features/tenants/tenantsSlice'
import { fetchRobotsAsync } from './features/robots/robotsSlice'
import { fetchCompositionsAsync } from './features/compositions/compositionsSlice'
import { fetchUsersAsync } from './features/users/usersSlice'
import { fetchDevicesAsync } from './features/devices/devicesSlice'
import { fetchFloorsAsync } from './features/floors/floorsSlice'
import { fetchMapsAsync } from './features/maps/mapsSlice'
import { fetchMissionsAsync } from './features/missions/missionsSlice'
import { fetchSchedulesAsync } from './features/schedules/schedulesSlice'
import { fetchSoundsAsync } from './features/sounds/soundsSlice'
import { fetchApplyInfosAsync, fetchFacesAsync, getFaceKeysAsync } from './features/faces/facesSlice'
import { fetchPresetsAsync } from './features/presets/presetsSlice'

type Menu = {
  title: string
  path: string
  component: React.FC<any>
  acceptRoles?: RoleType[]
  fetchers?: AsyncThunk<any, void, {}>[]
}

export const menu: Menu[] = [
  {
    title: 'テナント管理',
    path: '/tenant',
    component: TenantPage,
    fetchers: [fetchTenantAsync],
    acceptRoles: ['system']
  },
  {
    title: 'ロボット管理',
    path: '/robot',
    component: RobotPage,
    fetchers: [fetchRobotsAsync, fetchApplyInfosAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: '構成情報管理',
    path: '/composition',
    component: CompositionPage,
    fetchers: [fetchCompositionsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'ユーザー管理',
    path: '/user',
    component: UserPage,
    fetchers: [fetchUsersAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'デバイス管理',
    path: '/device',
    component: DevicePage,
    fetchers: [fetchDevicesAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'フロア管理',
    path: '/floor',
    component: FloorPage,
    fetchers: [fetchFloorsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: '地図画像管理',
    path: '/map',
    component: MapsPage,
    fetchers: [fetchMapsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'ミッションセット管理',
    path: '/mission',
    component: MissionPage,
    fetchers: [fetchMissionsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: '物体検知モード通知管理',
    path: '/preset',
    component: PresetsPage,
    fetchers: [fetchPresetsAsync, fetchRobotsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'スケジュール管理',
    path: '/schedule',
    component: SchedulePage,
    fetchers: [fetchSchedulesAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: '音声管理',
    path: '/sound',
    component: SoundPage,
    fetchers: [fetchSoundsAsync],
    acceptRoles: ['system', 'admin']
  },
  {
    title: '顔認証用画像管理',
    path: '/face',
    component: FacePage,
    fetchers: [fetchFacesAsync, getFaceKeysAsync],
    acceptRoles: ['system', 'admin'],
  },
  {
    title: 'イベント履歴',
    path: '/events',
    component: EventsPage,
    acceptRoles: ['system', 'admin']
  },
  {
    title: '操作履歴',
    path: '/operations',
    component: OperationsPage,
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'エラー履歴',
    path: '/errors',
    component: ErrorsPage,
    acceptRoles: ['system', 'admin']
  },
  {
    title: 'ログ',
    path: '/logs',
    component: LogsPage,
    acceptRoles: ['system']
  },
]
