import { api } from '../Api'
import { ErrorResponse } from '../commonTypes'
import { alert } from '../dialogs/alert'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { UpdatePresetsRequest, PresetsResponse } from './presetsTypes'

export async function fetchPresets() : Promise<PresetsResponse | undefined> {
  const res = await api.get(`/maintenance/object-detect-preset/list`)
  const json = await res.text()
  return camelizedParser(json)
}

export async function updatePresets(presets: UpdatePresetsRequest, robotId: string, tenantId: string) {
  const res = await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/object-detect-preset`, {
    body: kebabizedStringifier(presets)
  })
  if (!res.ok) {
    const errorRes = await res.json() as ErrorResponse
    alert('プリセット更新エラー', errorRes.error.message)
    return false
  }
  return true
}