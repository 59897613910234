import { api } from '../Api'
import { ErrorResponse } from '../commonTypes'
import { alert } from '../dialogs/alert'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { UserCreateRequest, UsersResponse } from './usersTypes'

export async function fetchUsers(): Promise<UsersResponse | undefined> {
  const res = await api.get('/maintenance/user/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function createUser(user: UserCreateRequest): Promise<Boolean> {
  const res = await api.post('/maintenance/user-create', {
    body: kebabizedStringifier(user)
  })
  if (!res.ok) {
    const errorRes = await res.json() as ErrorResponse
    if (!('error' in errorRes)) throw new Error('未対応のエラー')
    await alert('ユーザー作成エラー', errorRes.error.message)
    return false
  }
  return true
}

export async function deleteUser(userName: string) {
  await api.delete(`/maintenance/user/${userName}`)
}
