import { iconType } from "../../components/MyIcon"

export type OperationSearchOptions = {
  tenantId: string
  robotId: string
  startDate: Date
  endDate: Date
}

export type OperationLog = {
  actuators: string
  // robotId: string
  userId: string
  deviceId: string
  scheduleId: string
  operations: string
  operateAt: string
  jsonData: string
}

type ActuatorMap = {
  prefix: string
  icon: iconType
  color: string
  when?: { [key: string]: string }
}

export const actuatorsMap: Readonly<ActuatorMap[]> = [
  {
    prefix: 'light',
    icon: 'light',
    color: '#0000FF',
  },
  {
    prefix: 'buzzer',
    icon: 'buzzer',
    color: '#FF8D19',
  },
  {
    prefix: 'voice',
    icon: 'speaker',
    color: '#04C104',
  },
  {
    prefix: 'drive',
    icon: 'robot',
    color: '#FE0000',
    when: {
      'executed_schedule': "#EABB10",
      'navigate_to_request': "#1e90ff",
      'navigate_to_reset': "#1e90ff",
      'resume_mission': "#8fbc8f",
      'pause_mission': "#8fbc8f",
    }
  },
  {
    prefix: 'combination',
    icon: 'warnAction',
    color: '#E1272F',
  },
] as const
export const actuators = actuatorsMap.map(a => a.prefix)

export const onStates = ['on', 'reset_execution', 'execute_mission', 'unpause', 'executed_schedule', 'navigate_to_request','navigate_to_reset','resume_mission', 'pause_mission'] as const

export type OperationsResponse = {
  status: string
  data: OperationLog[]
}
