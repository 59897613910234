import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import {
  createFloorAsync,
  deleteFloorAsync,
  selectFloors,
  selectFloorsStatus,
} from '../features/floors/floorsSlice'
import { selectMaps } from '../features/maps/mapsSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import type { CreateFloorRequest } from '../features/floors/floorsTypes'
import { selectTenants } from '../features/tenants/tenantsSlice'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter, max255Validator, max50Validator, pathValidator } from '../features/utils'

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const FloorPage: React.FC = () => {
  // const classes = useStyles()
  // const [openCreateModal, setOpenCreateModal] = useState(false)
  const floors = useAppSelector(selectFloors)
  const floorsStatus = useAppSelector(selectFloorsStatus)
  const maps = useAppSelector(selectMaps)
  const tenants = useAppSelector(selectTenants)

  const dispatch = useAppDispatch()

  const cols: GridColDef[] = [
    { field: 'id', headerName: 'フロアID', minWidth: 150 },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
      // valueGetter({value, row})
      // {
      //   return tenants.find((t) => t.id === value)?.name ?? value
      // },
    },
    { field: 'name', headerName: 'フロア名', width: 200 },
    { field: 'mapUrl', headerName: '画像URL', width: 200 },
    { field: 'remarks', headerName: '備考', width: 300 },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
  ]

  return (
    <>
      <TableComponent
        loading={floorsStatus === 'loading'}
        createForm={{
          formTitle: 'フロア作成',
          FormRows: [
            {
              name: 'id',
              label: 'フロアID',
              validators: [
                pathValidator,
                {
                  re: /^.{0,20}$/,
                  helperText: '20文字以下である必要があります',
                }
              ],
            },
            {
              name: 'name',
              label: 'フロア名',
              validators: [
                max50Validator,
              ]
            },
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants.map(({ id, name }) => ({ name, value: id })),
            },
            {
              type: 'select',
              name: 'map',
              label: '地図画像名',
              items: maps,
              itemGetter: ({ fileName, path }) => ({
                name: fileName,
                value: fileName,
              }),
              filter(values, items) {
                return items.filter((i) => i.tenantId === values['tenantId'])
              },
            },
            // {
            //   type: 'file',
            //   name: 'mapImage',
            //   label: '地図画像',
            //   accept: 'image/png'
            // },
            {
              name: 'remarks',
              required: false,
              label: '備考',
              validators: [
                max255Validator,
              ]
            },
          ],
        }}
        onSubmit={async (values) => {
          const floor = {
            id: values['id'],
            name: values['name'],
            map: values['map'],
            remarks: values['remarks'],
          } as CreateFloorRequest
          dispatch(createFloorAsync([floor, values['tenantId'] as string]))
          return true
        }}
        onDelete={async ([keys]) => {
          const [id, tenantId] = String(keys).split('&')
          const floor = floors.find(
            (f) => f.tenantId === tenantId && f.id === id,
          )
          const tenant = tenants.find((t) => t.id === tenantId)
          if (
            floor &&
            (await confirm(
              'フロア削除',
              `本当に${tenant?.name ?? tenantId} / ${
                floor.name
              }を削除してもよろしいですか?`,
            ))
          ) {
            dispatch(deleteFloorAsync([id as string, floor.tenantId]))
          }
        }}
        getRowId={(row) => {
          return row.id + '&' + row.tenantId
        }}
        rows={floors}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default FloorPage
