import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createComposition, deleteComposition, fetchCompositions } from './compositionApi'
import type { Composition } from './compositionsTypes'


export interface compositionsState {
  compositions: Composition[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: compositionsState = {
  compositions: [],
  status: 'unload',
};

export const fetchCompositionsAsync = createAsyncThunk(
  'compositions/fetch',
  async () => {
    const response = await fetchCompositions().catch((err) => {
      console.log('compositions fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const createCompositionAsync = createAsyncThunk('compositions/create', async (composition: Composition) => {
  await createComposition(composition)
  return (await fetchCompositions())?.data
})

export const deleteCompositionAcync = createAsyncThunk('component/delete', async (compositionId: string) => {
  await deleteComposition(compositionId)
  return (await fetchCompositions())?.data
})

export const compositionsSlice = createSlice({
  name: 'compositions',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompositionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompositionsAsync.fulfilled, (state: compositionsState, action) => {
        state.status = 'loaded';
        state.compositions = action.payload
      })
      .addCase(createCompositionAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createCompositionAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.compositions = action.payload ?? state.compositions
      })
      .addCase(deleteCompositionAcync.fulfilled, (state, action) => {
        state.compositions = action.payload ?? state.compositions
      })
      
  },
});


export const selectCompositions = (state: RootState) => state.compositions.compositions;
export const selectCompositionsStatus = (state: RootState) => state.compositions.status

export default compositionsSlice.reducer;
