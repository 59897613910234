import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import {
  createTenantAsync,
  deleteTenantAsync,
  selectTenants,
  selectTenantsStatus,
} from '../features/tenants/tenantsSlice'
import TableComponent from '../components/TableComponent'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import type { Tenant } from '../features/tenants/tenantsTypes'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter, max50Validator } from '../features/utils'
import { Checkbox } from '@material-ui/core'

const tenantKindPlatformKakehashi: string = 'kakehashi'

const cols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 200 },
  { field: 'name', headerName: '名前', width: 300 },
  {
    field: 'kind',
    headerName: '梯対応',
    width: 100,
    disableColumnMenu: true,
    align: 'center',
    renderCell({ value }) {
      return <Checkbox checked={value === tenantKindPlatformKakehashi} disabled />
    },
  },
  // { field: 'remarks', headerName: '備考', width: 400 },
  {
    field: 'createAt',
    headerName: '作成日時',
    minWidth: 160,
    valueGetter: cellTimeGetter,
  },
  {
    field: 'updatedAt',
    headerName: '更新日時',
    minWidth: 160,
    hide: true,
    valueGetter: cellTimeGetter,
  },
]

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const TenantPage: React.FC = () => {
  // const classes = useStyles()
  // const [search, setSearch] = useState('')

  const tenants = useAppSelector(selectTenants)
  const tenantsStatus = useAppSelector(selectTenantsStatus)
  const dispatch = useAppDispatch()

  return (
    <>
      <TableComponent
        loading={tenantsStatus === 'loading'}
        createForm={{
          formTitle: 'テナント作成',
          FormRows: [
            {
              name: 'id',
              label: 'テナントID',
              defaultHelperText: '※後から変更できません',
              validators: [
                {
                  re: /^[a-z0-9]+$/,
                  helperText: '小文字英数字のみで入力してください',
                },
                {
                  re: /^.{0,9}$/,
                  helperText: '９文字以下である必要があります',
                },
              ],
              value: ''
            },
            {
              name: 'name',
              label: 'テナント名',
              validators: [
                max50Validator,
              ],
              value: ''
            },
            {
              type: 'checkbox',
              name: '_kind',
              label: '梯対応',
            },
            // {
            //   name: 'remarks',
            //   required: false,
            //   label: '備考',
            // },
          ],
        }}
        onSubmit={async (values) => {
          values.kind = values._kind as boolean ? tenantKindPlatformKakehashi : undefined
          delete values['_kind']
          dispatch(createTenantAsync(values as Tenant))
          return true
        }}
        onDelete={async ([id]) => {
          const tenant = tenants.find((t) => t.id === id)
          if (!tenant) return
          if (
            await confirm(
              'テナント削除',
              `本当に${tenant.name}を削除してもよろしいですか?
              削除する場合は「完全に削除」と入力してください。`,
              '完全に削除',
            )
          ) {
            dispatch(deleteTenantAsync(id as string))
          }
        }}
        rows={tenants}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default TenantPage
