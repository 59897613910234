import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { LoginResponseData } from '../Api'
import { RoleType } from '../users/usersTypes'
import { PasswdCredential, signin, signOut, trySigninWithRefreshToken } from './signinApi'

export interface SigninState {
  // userName: string
  user?: LoginResponseData
  status: 'signout' | 'loading' | 'signin' | 'failed'
}

const initialState: SigninState = {
  // userName: '',
  status: 'signout',
}

export const trySigninWithRefreshTokenAsync = createAsyncThunk(
  'signin/withRefleshToken',
  async () => {
    const response = await trySigninWithRefreshToken()
    return response
  },
)

export const signinAsync = createAsyncThunk(
  'signin/withPassword',
  async (passwdCredential: PasswdCredential) => {
    const response = await signin(passwdCredential)
    return response
  },
)

export const signOutAsync = createAsyncThunk(
  'signin/signOut',
  async () => {
    const response = await signOut()
    return response
  },
)

export const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(signinAsync.fulfilled, (state: SigninState, action) => {
        state.status = 'signin'
        state.user = action.payload
        console.log(action.payload)
      })
      .addCase(signinAsync.rejected, (state: SigninState, action) => {
        state.status = 'failed'
        console.warn('signinAsync failed')
      })
      .addCase(trySigninWithRefreshTokenAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(trySigninWithRefreshTokenAsync.fulfilled, (state: SigninState, action) => {
        state.status = 'signin'
        state.user = action.payload
      })
      .addCase(trySigninWithRefreshTokenAsync.rejected, (state: SigninState, action) => {
        state.status = 'signout'
      })
      .addCase(signOutAsync.fulfilled, (state, action) => {
        state.status = 'signout'
        state.user = undefined
      })
  },
})

export const selectSigninStatus = (state: RootState) => state.signin.status
export const selectUserName = (state: RootState) => state.signin.user?.userName
export const selectCurrentRoleType = (state: RootState): RoleType | undefined => (state.signin.user?.isSystemAdmin && 'system') || state.signin.user?.roleList[0].role
export const selectAffiliatedTenants = (state: RootState) => state.signin.user?.isSystemAdmin ? undefined : state.signin.user?.roleList.map(r => r.tenant)

export default signinSlice.reducer
