import { api } from '../Api'
import { ErrorResponse } from '../commonTypes'
import { alert } from '../dialogs/alert'
import { loading } from '../dialogs/loading'
import { camelizedParser, kebabizedStringifier } from '../utils'
import { RobotCreateRequest, RobotCreateResponse, RobotsResponse } from './robotsTypes'

export async function fetchRobots(): Promise<RobotsResponse | undefined> {
  const res = await api.get('/maintenance/robot/list')
  const json = await res.text()
  return camelizedParser(json)
}

export async function createRobot(robot: RobotCreateRequest, tenantId: string) {
  const closeLoading = loading("ロボット作成", "作成中...")
  const res = await api.post(`/maintenance/tenant/${tenantId}/admin/robot-create`, {
    body: kebabizedStringifier(robot)
  })
  closeLoading()
  if (res.ok) {
    const json = camelizedParser<RobotCreateResponse>(await res.text())
    console.log('robot created: ', json)
    alert("ロボット作成完了", (
      <>
        ロボットにインストールする証明書は以下からダウンロードできます。<br/>
        <a href={json.data.certificate} target="_blank" rel="noreferrer">ダウンロード</a>
      </>
    ))
  } else {
    const json = camelizedParser<ErrorResponse>(await res.text())
    alert("エラー", json.error.message)
  }
}

export async function deleteRobot(robotId: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}`)
}

export async function importRobotSetting(robotId: string, tenantId: string) {
  const input: HTMLInputElement = document.createElement('input')
  input.style.display = 'none'
  input.type = 'file'
  input.accept = 'application/json'
  input.addEventListener('change', async (ev) => {
    const target = ev.target as HTMLInputElement
    const file = target.files?.item(0)
    if (file) {
      const formData = new FormData()
      formData.append('upload_file', file)    
      await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/settings/import`, {
        body: formData
      }, true)
    }
  })
  document.body.appendChild(input)
  input.click()
  input.remove()
}

export async function exportRobotSetting(robotId: string, tenantId: string) {
  const res = await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/settings/export`)
  const url = window.URL.createObjectURL(await res.blob())
  const a = document.createElement('a')
  a.href = url
  a.download = `${tenantId}_${robotId}_settings.json`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export async function clearRobotStatus(robotId: string, tenantId: string) {
  const res = await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/status-clear`)
  if (!res.ok) {
    const json = camelizedParser<ErrorResponse>(await res.text())
    alert("エラー", json.error.message)
  }
}
