import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { fetchPresets, updatePresets } from './presetsApi'
import type { Preset, UpdatePresetsRequest } from './presetsTypes'

export interface PresetsState {
  presets: Preset[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: PresetsState = {
  presets: [],
  status: 'unload',
};

export const fetchPresetsAsync = createAsyncThunk(
  'presets/fetch',
  async () => {
    const response = await fetchPresets().catch((err) => {
      console.log('presets fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const updatePresetsAsync = createAsyncThunk('presets/update', async ([presets, robotId, tenantId]: [UpdatePresetsRequest, string, string]) => {
    if(await updatePresets(presets, robotId, tenantId)) {
      return (await fetchPresets())?.data
    } else {
      return null
    }
  }
)

export const presetsSlice = createSlice({
  name: 'presets',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresetsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPresetsAsync.fulfilled, (state: PresetsState, action) => {
        state.status = 'loaded';
        state.presets = action.payload
      })
      .addCase(updatePresetsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updatePresetsAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.presets = action.payload ?? state.presets
      })
  },
});


export const selectPresets = (state: RootState) => state.presets.presets;
export const selectPresetsStatus = (state: RootState) => state.presets.status;

export default presetsSlice.reducer;
