import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { SoundsResponse } from './soundsTypes'

export async function fetchSounds(): Promise<SoundsResponse | undefined> {
  const res = await api.get('/maintenance/sound-file/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function uploadSound(soudFile: File, tenantId: string) {
  const formData = new FormData()
  formData.append('upload_file', soudFile)
  await api.post(
    `/maintenance/tenant/${tenantId}/admin/sound-file-upload`,
    {
      body: formData,
    },
    true,
  )
}

export async function deleteSound(fileName: string, tenantId: string) {
  await api.delete(
    `/maintenance/tenant/${tenantId}/admin/sound-file-delete`,
    {
      body: kebabizedStringifier({fileName}),
    },
  )
}
