import {
  Button,
  DialogContent,
  makeStyles,
  Select,
  MenuItem,
  FormControlLabel,
  DialogActions,
  Chip,
  Switch,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import {
  Preset,
  KindName,
  KindTypeConv,
  UpdatePresetsRequest,
  EventSetting,
  KindNameConv,
} from '../../features/presets/presetsTypes'
import {
  EventModeSetting as CompositionEventModeSetting,
  EventSetting as CompositionEventSetting,
  Item,
  JsonComposition,
  SettingItem as CompositonSettingItem,
  Choice,
} from '../../features/compositions/compositionsTypes'
import {
  camelizedParser,
  getLastAuthUser,
  kebabizedStringifier,
} from '../../features/utils'
import { Robot } from '../../features/robots/robotsTypes'
import { selectRobots } from '../../features/robots/robotsSlice'
import { selectTenants } from '../../features/tenants/tenantsSlice'
import DividerWithChildren from '../DividerWithChildren'

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButton: {
    width: '50%',
  },
  settings: {
    display: 'flex',
    justifyContent: 'space-between',
  }
})

type PresetUISetting = {
  name: string
  items: Item[]
  eventSetting: CompositionEventSetting
}

type Props = {
  preset: Preset | undefined
  robot: Robot | undefined

  onSubmit?: (preset: UpdatePresetsRequest) => Promise<boolean>
  onClose?: () => void
}

const myCallback = (run: Function) => {
  return run()
}

type EditPresetUIProps = {
  usingModule: PresetUISetting[]
  settings?: EventSetting[]
  eventModeSetting?: CompositionEventModeSetting
}

// 変更項目の更新日時とユーザーを更新する
const updatePresetSettingLastUpdate = ({
  old,
  update,
}: {
  old: EventSetting[]
  update: EventSetting[]
}) => {
  const updateUser = getLastAuthUser()
  const updateDate = Math.floor(new Date().getTime() / 1000)
  update.forEach((newSettingModule) => {
    const oldSettingModule = old.find(
      (t) => t.module === newSettingModule.module
    )
    newSettingModule.items?.forEach((newItem) => {
      const oldItem = oldSettingModule?.items?.find(
        (t) => t.settingItem === newItem.settingItem
      )
      if (oldItem) {
        if (
          newItem.notificate !== oldItem.notificate ||
          newItem.status !== oldItem.status
        ) {
          newItem.lastUpdateDate = updateDate
          newItem.lastUpdateUser = updateUser
        }
      }
    })
  })
}

export default function EditPreset(props: Props) {
  const { preset, robot } = props

  const classes = useStyles()

  const [editPresetEventSettings, setEditPresetEventSettings] = useState<
    EventSetting[]
  >([])

  const robots = useAppSelector(selectRobots)
  const tenants = useAppSelector(selectTenants)

  const ios = useMemo(() => {
    return camelizedParser<JsonComposition>(
      robot?.robotCompositions.jsonData as string
    ).ios
  }, [robot])
  const eventSettings = ios.eventSettings
  const eventModeSetting = ios.eventModeSettings.find(
    (t) => t.mode === KindTypeConv[preset!.kind as keyof KindName]
  )

  const prevPresetEventSettings = useMemo(() => {
    const eventSettings = camelizedParser<EventSetting[]>(preset!.eventSettings)
    setEditPresetEventSettings(JSON.parse(JSON.stringify(eventSettings))) //乱暴な方法でコピー、現状のデータでは大丈夫な筈
    return eventSettings
  }, [preset])

  //プリセット設定moduleのnotificateを取得
  const presetSettingModuleNotificate = (module: string): boolean => {
    const notificate = editPresetEventSettings.find((t) => t.module === module)
      ?.items[0].notificate
    return notificate ? notificate : false
  }
  //プリセット設定moduleのnotificateを更新
  const togglePresetSettingModuleNotificate = (
    module: string,
    checked: boolean
  ) => {
    setEditPresetEventSettings((prevState) =>
      prevState.map((updateModule) =>
        updateModule.module === module
          ? {
              module: module,
              items: updateModule.items.map((item) => ({
                ...item,
                notificate: checked,
              })),
            }
          : updateModule
      )
    )
  }

  // //プリセット設定のnotificateを取得
  // const presetSettingNotificate = (module: string, key: string): boolean => {
  //   const notificate = editPresetEventSettings
  //     .find((t) => t.module === module)
  //     ?.items.find((t) => t.settingItem === key)?.notificate
  //   return notificate ? notificate : false
  // }
  // //プリセット設定のnotificateを更新
  // const togglePresetSettingNotificate = (module: string, key: string) => {
  //   setEditPresetEventSettings((prevState) =>
  //     prevState.map((updateModule) =>
  //       updateModule.module === module
  //         ? {
  //             module: module,
  //             items: updateModule.items.map((item) =>
  //               item.settingItem === key
  //                 ? { ...item, notificate: !item.notificate }
  //                 : item
  //             ),
  //           }
  //         : updateModule
  //     )
  //   )
  // }

  //プリセット設定のstatusを取得
  const presetSettingStatus = (module: string, key: string): number => {
    const status = editPresetEventSettings
      .find((t) => t.module === module)
      ?.items.find((t) => t.settingItem === key)?.status
    return status ? status : 0
  }

  //プリセット設定のstatusを更新
  const setPresetSettingStatus = (
    module: string,
    key: string,
    status: number
  ) => {
    const item = editPresetEventSettings
      .find((t) => t.module === module)
      ?.items.find((t) => t.settingItem === key)
    if (item) {
      setEditPresetEventSettings((prevState) =>
        prevState.map((updateModule) =>
          updateModule.module === module
            ? {
                module: module,
                items: updateModule.items.map((item) =>
                  item.settingItem === key ? { ...item, status: status } : item
                ),
              }
            : updateModule
        )
      )
    }
  }

  //プリセット設定のstatusを更新 (choice用)
  const setPresetSettingStatusByChoices = (
    module: string,
    key: string,
    status: number,
    compositionEventSetting: CompositionEventSetting
  ) => {
    const item = editPresetEventSettings
      .find((t) => t.module === module)
      ?.items.find((t) => t.settingItem === key)
    const choice = eventModeSetting?.settingItems.find(
      (t) => typeof t === 'object'
    ) as CompositonSettingItem
    const compositonEventSettingItem = compositionEventSetting.items?.find(
      (t) => t.settingItem === key
    )
    let compoitionItemChoice: Choice
    if (compositonEventSettingItem?.choices) {
      const selectItem = compositonEventSettingItem?.choices[status]
      if (selectItem) {
        Object.entries(choice.choices).forEach(([choicesKey, choicesValue]) => {
          if (choicesKey === selectItem) {
            status = choicesValue.selfValue
            compoitionItemChoice = choicesValue
          }
        })
      }
    }
    if (item) {
      setEditPresetEventSettings((prevState) =>
        prevState.map((updateModule) =>
          updateModule.module === module
            ? {
                module: module,
                items: updateModule.items.map((item) => {
                  if (item.settingItem === key) {
                    return { ...item, status: Number(status) }
                  }
                  if (compoitionItemChoice) {
                    if (
                      compoitionItemChoice.otherValues[item.settingItem] !==
                      undefined
                    ) {
                      const newStatus =
                        compoitionItemChoice.otherValues[item.settingItem]
                      return { ...item, status: newStatus }
                    }
                  }
                  return item
                }),
              }
            : updateModule
        )
      )
    }
  }

  // overallの場合に配下を非表示とするか
  const isDisplayByOverall = (value: PresetUISetting) => {
    return value.eventSetting?.overall
      ? presetSettingStatus(
          value.eventSetting.module,
          value.eventSetting.module
        ) === 1
      : true
  }

  const EditPresetUI = ({ usingModule }: EditPresetUIProps): JSX.Element => {
    const labelPlacement = 'start'
    return (
      <>
        {Object.entries(usingModule).map(([, value]) => (
          <div key={value.name}>
            <DividerWithChildren>
              <Chip label={value.name} />
            </DividerWithChildren>

            {value.eventSetting?.overall && (
              <FormControlLabel className={classes.settings}
                control={
                  <Switch
                    name={value.eventSetting.module}
                    color='primary'
                    onChange={(event) => {
                      const target: HTMLInputElement =
                        event.target as HTMLInputElement
                      setPresetSettingStatus(
                        value.eventSetting.module,
                        value.eventSetting.module,
                        target.checked ? 1 : 0
                      )
                    }}
                    checked={
                      presetSettingStatus(
                        value.eventSetting.module,
                        value.eventSetting.module
                      ) === 1
                    }
                  />
                }
                label={value.name}
                labelPlacement={labelPlacement}
              />
            )}

            {value.eventSetting?.notificate && (
              <FormControlLabel className={classes.settings}
                control={
                  <Switch
                    name={value.eventSetting.module}
                    color='primary'
                    checked={presetSettingModuleNotificate(
                      value.eventSetting.module
                    )}
                    onChange={(event) => {
                      const target: HTMLInputElement =
                        event.target as HTMLInputElement
                      togglePresetSettingModuleNotificate(
                        value.eventSetting.module,
                        target.checked
                      )
                    }}
                  />
                }
                label={`${value.name}の通知`}
                labelPlacement={labelPlacement}
              />
            )}

            {Object.entries(value.items).map(([key, item]) => {
              if (item.choices) {
                return (
                  <div key={item.name}>
                    <FormControlLabel className={classes.settings}
                      style={{
                        visibility: isDisplayByOverall(value)
                          ? 'visible'
                          : 'hidden',
                      }}
                      key={item.name}
                      control={
                        <Select
                          name={item.settingItem}
                          labelId={item.settingItem}
                          onChange={(event) => {
                            const target: HTMLInputElement =
                              event.target as HTMLInputElement
                            setPresetSettingStatusByChoices(
                              value.eventSetting.module,
                              item.settingItem,
                              Number(target.value),
                              value.eventSetting
                            )
                          }}
                          value={presetSettingStatus(
                            value.eventSetting.module,
                            item.settingItem
                          )}
                        >
                          {Object.entries(item.choices).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      label={item.name}
                      labelPlacement={labelPlacement}
                    />
                  </div>
                )
              } else if (item.range) {
                return (
                  <div key={item.name}>
                    <FormControlLabel className={classes.settings}
                      style={{
                        visibility: isDisplayByOverall(value)
                          ? 'visible'
                          : 'hidden',
                      }}
                      key={item.name}
                      control={
                        <Select
                          name={item.settingItem}
                          labelId={item.settingItem}
                          onChange={(event) => {
                            const target: HTMLInputElement =
                              event.target as HTMLInputElement
                            setPresetSettingStatus(
                              value.eventSetting.module,
                              item.settingItem,
                              Number(target.value)
                            )
                          }}
                          value={presetSettingStatus(
                            value.eventSetting.module,
                            item.settingItem
                          )}
                        >
                          {myCallback(() => {
                            const list = []
                            for (
                              let i = item.range!.min;
                              i <= item.range!.max;
                              i++
                            ) {
                              list.push(
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              )
                            }
                            return list
                          })}
                        </Select>
                      }
                      label={item.name}
                      labelPlacement={labelPlacement}
                    />
                  </div>
                )
              // } else if (value.eventSetting.module === 'face') {
              //   return (
              //     <div key={item.name}>
              //       <FormControlLabel className={classes.settings}
              //         style={{
              //           visibility: isDisplayByOverall(value)
              //             ? 'visible'
              //             : 'hidden',
              //         }}
              //         key={item.name}
              //         control={
              //           <Switch
              //             name={item.settingItem}
              //             color='primary'
              //             checked={presetSettingNotificate(
              //               value.eventSetting.module,
              //               item.settingItem
              //             )}
              //             onChange={() =>
              //               togglePresetSettingNotificate(
              //                 value.eventSetting.module,
              //                 item.settingItem
              //               )
              //             }
              //           />
              //         }
              //         label={item.name}
              //         labelPlacement={labelPlacement}
              //       />
              //     </div>
              //   )
              } else {
                return (
                  // isDisplayByOverall(value) &&
                  <div key={item.name}>
                    <FormControlLabel className={classes.settings}
                      style={{
                        visibility: isDisplayByOverall(value)
                          ? 'visible'
                          : 'hidden',
                      }}
                      key={item.name}
                      control={
                        <Switch
                          name={item.settingItem}
                          color='primary'
                          onChange={(event) => {
                            const target: HTMLInputElement =
                              event.target as HTMLInputElement
                            setPresetSettingStatus(
                              value.eventSetting.module,
                              item.settingItem,
                              target.checked ? 1 : 0
                            )
                          }}
                          checked={
                            presetSettingStatus(
                              value.eventSetting.module,
                              item.settingItem
                            ) === 1
                          }
                        />
                      }
                      label={item.name}
                      labelPlacement={labelPlacement}
                    />
                  </div>
                )
              }
            })}
          </div>
        ))}
      </>
    )
  }

  const usingModules = useMemo(() => {
    const usingModules: PresetUISetting[] = []

    eventSettings.forEach((eventSetting) => {
      let moduleEnable: boolean = false
      let itemEnable = false
      const items: Item[] = []

      if (eventSetting.notificate) {
        moduleEnable = true
      }

      if (eventSetting.overall) {
        moduleEnable = true
      }

      eventSetting.items?.forEach((item) => {
        if (
          eventModeSetting?.settingItems.findIndex((s) =>
            typeof s == 'string'
              ? s === item.settingItem
              : s.settingItem === item.settingItem
          ) !== -1
        ) {
          moduleEnable = true
          itemEnable = true
          items.push(item)
        }
      })

      if (!itemEnable) {
        moduleEnable = false
      }

      // 顔は特例、eventModeSetting内にないが表示する
      // if (eventSetting.module === 'face') {
      //   moduleEnable = true
      //   eventSetting.items?.forEach((item) => {
      //     items.push(item)
      //   })
      // }

      // 有効なものだけ追加
      if (moduleEnable) {
        usingModules.push({
          name: eventSetting.name,
          eventSetting: eventSetting,
          items: items,
        })
      }
    })
    return usingModules
  }, [eventSettings, eventModeSetting])

  const savePreset = () => {
    updatePresetSettingLastUpdate({
      old: prevPresetEventSettings,
      update: editPresetEventSettings,
    })

    const req: UpdatePresetsRequest = {
      tenantId: preset!.tenantId,
      robotId: preset!.robotId,
      kind: preset!.kind,
      eventSettings: JSON.stringify(
        JSON.parse(kebabizedStringifier(editPresetEventSettings)),
        undefined,
        2
      ),
    }

    if (props.onSubmit) {
      props.onSubmit(req).then((res) => {
        if (res) props.onClose?.()
      })
    }
  }

  return (
    <>
      <DialogTitle>プリセット編集</DialogTitle>
      <DialogContent>
        <>
          <Typography>
            ロボット名：{robots.find((t) => t.id === preset?.robotId)?.name}
          </Typography>
          <Typography>
            所属テナント名：
            {tenants.find((t) => t.id === preset?.tenantId)?.name}
          </Typography>
          <Typography>
            モード名：{KindNameConv[preset?.kind as keyof KindName]}
          </Typography>
        </>
        <EditPresetUI
          usingModule={usingModules}
          settings={editPresetEventSettings}
          eventModeSetting={eventModeSetting}
        ></EditPresetUI>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant='contained' color='default' onClick={props.onClose}>
          キャンセル
        </Button>
        <Button
          variant='contained'
          color='primary'
          className={classes.addButton}
          onClick={savePreset}
        >
          保存
        </Button>
      </DialogActions>
    </>
  )
}
