import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { deleteSound, fetchSounds, uploadSound } from './soundsApi'
import type { Sound } from './soundsTypes'


export interface soundsState {
  sounds: Sound[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: soundsState = {
  sounds: [],
  status: 'unload',
};

export const fetchSoundsAsync = createAsyncThunk(
  'sounds/fetch',
  async () => {
    const response = await fetchSounds().catch((err) => {
      console.log('sounds fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const uploadSoundAsync = createAsyncThunk(
  'sounds/upload',
  async ([soundFile, tenantId]: [File, string]) => {
    await uploadSound(soundFile, tenantId)
    return (await fetchSounds())?.data
  }
)

export const deleteSoundAsync = createAsyncThunk(
  'sounds/delete',
  async ([fileName, tenantId]: [string, string]) => {
    await deleteSound(fileName, tenantId)
    return (await fetchSounds())?.data
  }
)

export const soundsSlice = createSlice({
  name: 'sounds',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSoundsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSoundsAsync.fulfilled, (state: soundsState, action) => {
        state.status = 'loaded';
        state.sounds = action.payload
      })
      .addCase(uploadSoundAsync.fulfilled, (state, action) => {
        state.sounds = action.payload ?? state.sounds
      })
      .addCase(deleteSoundAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSoundAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.sounds = action.payload ?? state.sounds
      })
  },
});


export const selectsounds = (state: RootState) => state.sounds.sounds;
export const selectsoundsStatus = (state: RootState) => state.sounds.status

export default soundsSlice.reducer;
