import { api } from '../Api'
import { ErrorResponse } from '../commonTypes'
import { alert } from '../dialogs/alert'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { CreateScheduleRequest, SchedulesResponse, UpdateScheduleRequest } from './schedulesTypes'

export async function fetchSchedules(): Promise<SchedulesResponse | undefined> {
  const res = await api.get('/maintenance/schedule/list')
  const json = await res.text()
  return camelizedParser(json)
}

export async function createSchedule(schedule: CreateScheduleRequest, robotId: string, tenantId: string) {
  await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/schedule-create`, {
    body: kebabizedStringifier(schedule)
  })
}

export async function updateSchedule(schedule: UpdateScheduleRequest) {
  const scheduleId = schedule.scheduleId
  delete schedule.scheduleId
  const res = await api.put(`/maintenance/schedule/${scheduleId}`, {
    body: kebabizedStringifier(schedule)
  })
  if (!res.ok) {
    const errorRes = await res.json() as ErrorResponse
    alert('スケジュール更新エラー', errorRes.error.message)
    return false
  }
  return true
}

export async function deleteSchedule(scheduleId: string) {
  await api.delete(`/maintenance/schedule/${scheduleId}`)
}

//[CSPARX-100]
export async function importSchedule(csv: File, tenantId: string) {
  const formData = new FormData()

  // [CSPARX-117] csvの文字コードをここで変換。->
  const binaryString = await readAsBinaryString(csv);
  console.log(binaryString);
  const blob = new Blob([binaryString], { type: 'text/plain' })
  
  formData.append('upload_file', blob)
  // <-

  //formData.append('upload_file', csv)
  const res = await api.post(`/maintenance/tenant/${tenantId}/admin/schedule/import`, {
    body: formData
  }, true)

  if (!res.ok) {
    const errorRes = await res.json() as ErrorResponse
    alert('スケジュールインポートエラー', errorRes.error.message)
    return false
  }
  return true
}

  // [CSPARX-117]
  function readAsBinaryString(file:Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryString = reader.result;

      var result = '';
      var array = Encoding.stringToCode(binaryString)

      var detectedEncoding = Encoding.detect(array);
      console.log('CSV Encoding is ' + detectedEncoding);

      var convArray = Encoding.convert( array, {
        to: 'UNICODE',
        from: detectedEncoding
      });

      result = Encoding.codeToString(convArray);

      resolve(result);
    };
    
    reader.onerror = () => {
      console.log('ファイルが読み込めませんでした。');
      reject('');
    };

    reader.readAsBinaryString(file);
    const Encoding = require('encoding-japanese');
  });
}
