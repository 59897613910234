import { makeStyles } from "@material-ui/styles"
import React from "react"

import light from '../assets/icons/lamp.png'
import buzzer from '../assets/icons/buzzer.png'
import robot from '../assets/icons/robot.png'
import speaker from '../assets/icons/speaker.png'
import warnAction from '../assets/icons/warnAction.svg'
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1.5em',
    height: '1.5em',
    verticalAlign: 'baseline',
    display: 'inline-block',
    mask: 'no-repeat center/100%',
    WebkitMask: 'no-repeat center/100%',
    background: 'darkorange',
  },
  light: {
    maskImage: `url(${light})`,
  },
  buzzer: {
    maskImage: `url(${buzzer})`,
  },
  robot: {
    maskImage: `url(${robot})`,
  },
  speaker: {
    maskImage: `url(${speaker})`,
  },
  warnAction: {
    maskImage: `url(${warnAction})`,
  },
}))

export type iconType = 'light' | 'buzzer' | 'robot' | 'speaker' | 'warnAction'

type Prop = {
  type: iconType
  color?: string
  className?: string
}

export const MyIcon: React.FC<Prop> = ({ type, color, className }) => {
  const classes = useStyles()
  return <div className={clsx(classes.icon, classes[type], className)} style={{ background: color }} />
}
