import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { CreateFloorRequest, FloorsResponse } from './floorsTypes'

export async function fetchFloors(): Promise<FloorsResponse | undefined> {
  const res = await api.get('/maintenance/floor/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function createFloor(floor: CreateFloorRequest, tenantId: string) {
  await api.post(`/maintenance/tenant/${tenantId}/admin/floor-create`,{
    body: kebabizedStringifier(floor)
  })
}

export async function deleteFloor(floorId: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/floor/${floorId}`)
}
