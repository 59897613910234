import React from 'react'
import { Dialog, DialogProps, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import { camelizedParser } from '../utils'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  robotName: string
  faceList: string
} & DialogProps

const FaceList: React.FC<Props> = (props) => {
  const {robotName, faceList: facesJson, ...dialogProps} = props

  let faceList = ''
  try {
    faceList = camelizedParser(facesJson)
  } catch {
    return null
  }
  
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        {robotName}の顔認証情報適用状況
        <IconButton
          onClick={() => {
            props.onClose?.({}, 'backdropClick')
          }}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray',
          }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre' }}>
          {
            // TBD: face-listの中身次第のため仮にJSONを整形して表示
            JSON.stringify(faceList, null, "    ")
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default FaceList
