import React, { useState } from 'react'
// import { createStyles, makeStyles } from '@material-ui/core'
import { useAppSelector } from '../app/hooks'
import {
  selectTenantsForSelect,
  selectTenantsStatus,
} from '../features/tenants/tenantsSlice'
import Form from '../components/Form'
import {
  selectRobots,
  selectRobotsStatus,
} from '../features/robots/robotsSlice'
import { Container } from '@material-ui/core'
import { ErrorList } from '../features/errors/ErrorList'
import { ErrorSearchOptions } from '../features/errors/errorsTypes'

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     actionsContainer: {
//       width: '100%',
//       display: 'flex',
//       justifyContent: 'flex-end',
//       marginBottom: '10px',
//     },
//     action: {
//       margin: '10px',
//     },
//   }),
// )

const ErrorsPage: React.FC = () => {
  // const classes = useStyles()
  // const [openCreateModal, setOpenCreateModal] = useState(false)
  const tenants = useAppSelector(selectTenantsForSelect)
  const tenantsState = useAppSelector(selectTenantsStatus)
  const robots = useAppSelector(selectRobots)
  const robotsState = useAppSelector(selectRobotsStatus)

  const [showResult, setShowResult] = useState(false)
  const [searchOptions, setSearchOptions] = useState({} as ErrorSearchOptions)

  const onGoBack = () => {
    setShowResult(false)
  }

  return (tenantsState === 'loaded' || tenantsState === 'unload') &&
    robotsState === 'loaded' ? (
    !showResult ? (
      <Container maxWidth="sm">
        <Form
          formTitle="検索条件"
          FormRows={[
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
              value: searchOptions.tenantId,
            },
            {
              type: 'select',
              name: 'robotId',
              label: 'ロボット',
              required: false,
              items: robots,
              value: searchOptions.robotId,
              itemGetter(item) {
                return {
                  name: item.name,
                  value: item.id,
                }
              },
              filter(values, items) {
                return items.filter((i) => i.tenantId === values['tenantId'])
              },
            },
            {
              type: 'radiogroup',
              name: 'searchAw04',
              defaultValue: 'all',
              required: false,
              items:[{
                      type: 'radio',
                      name: 'all',
                      label: '全てを取得',
                      value: 'all',
                    },
                    {
                      type: 'radio',
                      name: 'otherCode',
                      label: '通常のエラーコード（AW-04以外）を取得',
                      value: 'otherCode',
                    },
                    {
                      type: 'radio',
                      name: 'aw04Only',
                      label: 'ステータス変化(AW-04のみ)を取得',
                      value: 'aw04Only',
                    }]
            },
            {
              type: 'datetime',
              name: 'startDatetime',
              label: '検索開始日時',
              required: false,
              value:
                searchOptions.startDate ??
                (() => {
                  const date = new Date()
                  date.setDate(date.getDate() - 7)
                  date.setHours(0)
                  date.setMinutes(0)
                  date.setSeconds(0)
                  return date
                })(),
            },
            {
              type: 'datetime',
              name: 'endDatetime',
              label: '検索終了日時',
              required: false,
              value:
                searchOptions.endDate ??
                (() => {
                  const date = new Date()
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  return date
                })(),
            },
            // {
            //   type: 'select',
            //   name: 'type',
            //   label: 'エラータイプ',
            //   required: false,
            //   multiple: true,
            //   items: eventTags,
            //   value: searchOptions.type
            // },
          ]}
          createText="検索"
          onSubmit={(values) => {
            const options = {
              tenantId: values['tenantId'] as string,
              robotId: (typeof values['robotId'] === 'string'
                ? values['robotId']
                : '') as string,
              startDate: values['startDatetime'] as Date,
              endDate: values['endDatetime'] as Date,
              type: 'robot',
              onlyCode: (values['searchAw04'] === 'aw04Only' ? "AW-04" : '') as string,
              notCode: (values['searchAw04'] === 'otherCode' ? "AW-04" : '') as string,
            } as ErrorSearchOptions
            if (values['tenantId']) {
              setSearchOptions(options)
            }
            setShowResult(true)
          }}
        />
      </Container>
    ) : (
      <ErrorList goBack={onGoBack} searchOptions={searchOptions} />
    )
  ) : null
}

export default ErrorsPage
