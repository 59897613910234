import { api } from '../Api'
import { camelizedParser } from '../utils'
import type { DevicesResponse } from './devicesTypes'

export async function fetchDevices(): Promise<DevicesResponse | undefined> {
  const res = await api.get('/maintenance/device/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function deleteDevice(deviceToken: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/device/${deviceToken}`)
}
