import React from 'react'
import type { GridColDef, GridRowIdGetter } from '@material-ui/data-grid'
import {
  createCompositionAsync,
  deleteCompositionAcync,
  selectCompositions,
  selectCompositionsStatus,
} from '../features/compositions/compositionsSlice'
import TableComponent from '../components/TableComponent'
import type { Composition } from '../features/compositions/compositionsTypes'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { Button } from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { confirm } from '../features/dialogs/confirm'
import { copyToClipboard, cellTimeGetter, pathValidator, max50Validator, max255Validator } from '../features/utils'

const cols: GridColDef[] = [
  { field: 'compositionId', headerName: 'ID', width: 200 },
  { field: 'name', headerName: '名前', width: 250 },
  {
    field: 'jsonData',
    headerName: '構成情報',
    sortable: false,
    disableColumnMenu: true,
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell({ value }) {
      return (
        <Button
          onClick={() => {
            copyToClipboard(
              JSON.stringify(JSON.parse(value as string), null, '  '),
            )
          }}
          title="構成情報のコピー"
        >
          <FileCopyIcon />
        </Button>
      )
    },
  },
  { field: 'remarks', headerName: '備考', minWidth: 300, flex: 1 },
  {
    field: 'createAt',
    headerName: '作成日時',
    minWidth: 160,
    valueGetter: cellTimeGetter,
  },
  {
    field: 'updatedAt',
    headerName: '更新日時',
    minWidth: 160,
    hide: true,
    valueGetter: cellTimeGetter,
  },
]

const idGetter: GridRowIdGetter = (row) => {
  return row.compositionId
}

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const CompositionPage: React.FC = () => {
  // const classes = useStyles()
  const dispatch = useAppDispatch()
  const compositions = useAppSelector(selectCompositions)
  const compositionsStatus = useAppSelector(selectCompositionsStatus)

  return (
    <>
      <TableComponent
        loading={compositionsStatus === 'loading'}
        createForm={{
          formTitle: '構成情報作成',
          FormRows: [
            {
              name: 'compositionId',
              label: '構成情報ID',
              validators: [
                pathValidator,
                max50Validator,
              ]
            },
            {
              name: 'name',
              label: '構成情報名',
              validators: [
                max50Validator,
              ]
            },
            {
              type: 'file',
              name: 'jsonData',
              label: '構成情報',
              accept: 'application/json',
            },
            {
              name: 'remarks',
              required: false,
              label: '備考',
              validators: [
                max255Validator,
              ]
            },
          ],
        }}
        onSubmit={async (values) => {
          const { jsonData, ...compositions } = values
          if (!(jsonData instanceof File)) return
          compositions['jsonData'] = await jsonData.text()
          dispatch(createCompositionAsync(compositions as Composition))
          return true
        }}
        onDelete={async ([id]) => {
          const composition = compositions.find((c) => c.compositionId === id)
          if (!composition) return
          if (
            await confirm(
              '構成情報削除',
              `本当に${composition.name}を削除してもよろしいですか?`,
            )
          ) {
            dispatch(deleteCompositionAcync(id as string))
          }
        }}
        getRowId={idGetter}
        rows={compositions}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default CompositionPage
