import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { createSchedule, deleteSchedule, fetchSchedules, updateSchedule, importSchedule } from './schedulesApi'
import type { CreateScheduleRequest, Schedule } from './schedulesTypes'

export interface schedulesState {
  schedules: Schedule[]
  status: 'unload' | 'loading' | 'loaded'
}

const initialState: schedulesState = {
  schedules: [],
  status: 'unload',
}

export const fetchSchedulesAsync = createAsyncThunk(
  'schedules/fetch',
  async () => {
    const response = await fetchSchedules().catch((err) => {
      console.log('schedules fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  },
)

export const createScheduleAsync = createAsyncThunk(
  'schedules/create',
  async ([schedule, robotId, tenantId]: [CreateScheduleRequest, string, string]) => {
    await createSchedule(schedule, robotId, tenantId)
    return (await fetchSchedules())?.data
  },
)

export const updateScheduleAsync = createAsyncThunk(
  'schedules/update',
  async ([schedule]: [CreateScheduleRequest]) => {
    if(await updateSchedule(schedule)) {
      return (await fetchSchedules())?.data
    } else {
      return null
    }
  },
)

export const deleteScheduleAsync = createAsyncThunk(
  'schedules/delete',
  async (scheduleId: string) => {
    await deleteSchedule(scheduleId)
    return (await fetchSchedules())?.data
  },
)

// [CSPARX-100]
export const importScheduleAsync = createAsyncThunk(
  'csv/import',
  async ([csv, tenantId]: [File, string]) => {
    if(await importSchedule(csv, tenantId)){
      return (await fetchSchedules())?.data
    } else {
      return null
    }
  },
)

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedulesAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSchedulesAsync.fulfilled, (state: schedulesState, action) => {
        state.status = 'loaded'
        state.schedules = action.payload
      })
      .addCase(createScheduleAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createScheduleAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.schedules = action.payload ?? state.schedules
      })
      .addCase(updateScheduleAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateScheduleAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.schedules = action.payload ?? state.schedules
      })
      .addCase(deleteScheduleAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteScheduleAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.schedules = action.payload ?? state.schedules
      })
      // [CSPARX-100]
      .addCase(importScheduleAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(importScheduleAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.schedules = action.payload ?? state.schedules
      })    
  },
})

export const selectSchedules = (state: RootState) => state.schedules.schedules
export const selectSchedulesStatus = (state: RootState) => state.schedules.status

export default schedulesSlice.reducer
