import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { createTenant, deleteTenant, fetchTenants } from './tenantsApi'
import type { Tenant } from './tenantsTypes'

export interface TenantsState {
  tenants?: Tenant[]
  status: 'unload' | 'loading' | 'loaded'
}

const initialState: TenantsState = {
  status: 'unload',
}

export const fetchTenantAsync = createAsyncThunk('tenants/fetch', async () => {
  const response = await fetchTenants().catch((err) => {
    console.log('tenants fetch error' + err)
  })
  if (response) {
    return response.data ?? []
  } else {
    return []
  }
})

export const createTenantAsync = createAsyncThunk(
  'tenants/create',
  async (tenant: Tenant) => {
    await createTenant(tenant)
    return (await fetchTenants())?.data
  },
)

export const deleteTenantAsync = createAsyncThunk(
  'tenants/delete',
  async (tenantId: string) => {
    await deleteTenant(tenantId).catch((err) => {
      console.error(err)
    })
    return (await fetchTenants())?.data
  },
)

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTenantAsync.fulfilled, (state: TenantsState, action) => {
        state.status = 'loaded'
        state.tenants = action.payload
      })
      .addCase(createTenantAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createTenantAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.tenants = action.payload ?? state.tenants
      })
      .addCase(deleteTenantAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteTenantAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.tenants = action.payload ?? state.tenants
      })
  },
})

export const selectTenants = (state: RootState) => state.tenants.tenants ??
  state.signin.user?.roleList.map((r) => ({
    id: r.tenant,
    name: r.tenant,
  })) ??
  []
export const selectTenantsStatus = (state: RootState) => state.tenants.status
export const selectTenantsForSelect = (state: RootState) =>
  state.tenants.tenants?.map(({ id: value, name }) => ({ name, value })) ??
  state.signin.user?.roleList.map((r) => ({
    value: r.tenant,
    name: r.tenant,
  })) ??
  []
export const selectFilteredTenants = (search: string) => (state: RootState) =>
  state.tenants.tenants?.filter((t) =>
    Object.values(t).some((v) => v.includes(search)),
  ) ?? []

export default tenantsSlice.reducer
