import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { deleteDevice, fetchDevices } from './deviceApi'
import type { Device } from './devicesTypes'


export interface devicesState {
  devices: Device[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: devicesState = {
  devices: [],
  status: 'unload',
};

export const fetchDevicesAsync = createAsyncThunk(
  'devices/fetch',
  async () => {
    const response = await fetchDevices().catch((err) => {
      console.log('devices fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const deleteDeviceAsync = createAsyncThunk(
  'devices/delete',
  async([deviceToken, tenantId]: [string, string]) => {
    await deleteDevice(deviceToken, tenantId)
    return (await fetchDevices())?.data
  }
)


export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDevicesAsync.fulfilled, (state: devicesState, action) => {
        state.status = 'loaded';
        state.devices = action.payload
      })
      .addCase(deleteDeviceAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteDeviceAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.devices = action.payload ?? state.devices
      })
  },
});


export const selectdevices = (state: RootState) => state.devices.devices;
export const selectdevicesStatus = (state: RootState) => state.devices.status

export default devicesSlice.reducer;
