import React, { createRef, useState } from 'react'
import { TextField, makeStyles, createStyles, Button } from '@material-ui/core'
import type { FormFileRowProps } from './FormRows'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    fileInput: {
      display: 'none',
    },
    fileNameRoot: {
      backgroundColor: '#F8F8F8',
    },
    fileButton: {
      margin: '8px 0px 5px 4px',
      height: '40px',
    },
  }),
)

type Props = Omit<FormFileRowProps, 'type'> & {
  onChange?: (name: string, file: File | null) => void
  className?: string,
  error?: boolean,
  helperText?: string,
}

const FileInputRow: React.FC<Props> = ({ value, accept, onChange, name, className, label, error, helperText }) => {
  const classes = useStyles()
  const fileInputRef = createRef<HTMLInputElement>()
  const [fileName, setFileName] = useState(value ?? '')

  const handleFileSelection = () => {
    fileInputRef.current?.click()
  }

  const handleFileChanged: React.ChangeEventHandler<HTMLInputElement> = (
    ev,
  ) => {
    const file = ev.target.files?.[0]
    if (
      file &&
      (accept == null ||
        file.type === accept ||
        (accept.endsWith('*') &&
          file.type.startsWith(accept.slice(0, -1))))
    ) {
      setFileName(file.name ?? '')
    }
    onChange?.(name, file ?? null)
  }

  return (
    <div className={classes.root + ' ' + className}>
      <TextField
        inputProps={{ readOnly: true }}
        variant="outlined"
        onDoubleClick={handleFileSelection}
        label={label}
        value={fileName}
        error={error}
        helperText={helperText}
        margin="dense"
        classes={{
          root: classes.fileNameRoot,
        }}
      />
      <Button
        variant="contained"
        classes={{ root: classes.fileButton }}
        onClick={handleFileSelection}
        disableElevation
      >
        ファイルを選択
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        className={classes.fileInput}
        onChange={handleFileChanged}
        accept={accept}
      />
    </div>
  )
}

export default FileInputRow
