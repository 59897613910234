import { makeStyles } from "@material-ui/core"

export const useFormStyles = makeStyles((theme) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '90vh',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px white',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        boxShadow: '1px solid slategrey',
      },
    },
    select: {
      display: 'box',
      margin: '10px 0 5px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    row: {
      marginTop: '10px',
    },
    saveButton: {
      width: '50%',
    },
    spacer: {
      flexGrow: 1
    }
  })
)
