import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'

import noseImage from '../../assets/nose.png'
import soundImage from '../../assets/sound.png'

const optionsConverter = <T extends { [key: string]: { text: string } }>(
  obj: T,
  suffix: string = '',
) => {
  return Object.entries(obj).map(([k, v]) => ({
    ...v,
    name: v.text + suffix,
    value: k,
  }))
}

export type EventsSearchOptions = {
  startDate?: Date
  endDate?: Date
  tenantId: string
  robotId?: string[]
  tag?: typeof eventTags[number]['value'][]
  eventKind?: typeof eventKinds[number]['value'][]
  eventSubKind?: typeof eventSubKinds[number]['value'][]
  status?: typeof eventStatuses[number]['value'][]
}

export type EventExportOptions = {
  movie: boolean
  imageFrame: boolean
  imageOrigin: boolean
  csv: boolean
}

export type GuardEventType = 'object'|'action'|'person'|'heat'|'left'|'face'|'sound'|'smell'|'collision'
export const guardEventTypes: {
  [key: string]: { text: string; eventImage?: string }
} = {
  object: {
    text: '物体',
  },
  action: {
    text: '行動',
  },
  person: {
    text: '人',
  },
  heat: {
    text: '高温',
  },
  left: {
    text: '置き去り',
  },
  face: {
    text: '顔',
  },
  sound: {
    text: '異音',
    eventImage: soundImage,
  },
  smell: {
    text: '匂い',
    eventImage: noseImage,
  },
  collision: {
    text: '衝突',
  },
} as const
export const eventKinds = optionsConverter(guardEventTypes, '検知')

export const guardEventSubKinds = {
  knife: {
    text: '危険物検知',
    subOf: 'object',
  },
  white_stick: {
    text: '白杖検知',
    subOf: 'object',
  },
  fallen: {
    text: '転倒検知',
    subOf: 'action',
  },
  smoking: {
    text: '喫煙検知',
    subOf: 'action',
  },
  fight: {
    text: '喧嘩検知',
    subOf: 'action',
  },
  sleeping: {
    text: '居眠り検知',
    subOf: 'action',
  },
  drunk: {
    text: '泥酔検知',
    subOf: 'action',
  },
} as const
export type GuardEventSubKind = keyof typeof guardEventSubKinds
export const eventSubKinds = optionsConverter(guardEventSubKinds) as unknown as { name: string, value: string, subOf: GuardEventType }[]

export type GuardEventTag = 'correct' | 'incorrect' | 'other'
export const guardEventTags: {
  [key: string]: { text: string; color?: string }
} = {
  correct: {
    text: '正報',
  },
  incorrect: {
    text: '誤報',
    color: '#FF9696',
  },
  other: {
    text: 'その他',
  },
} as const
export const eventTags = optionsConverter(guardEventTags)

export const guardEventStatuses = {
  unprocessed: {
    text: '未対応',
    icon: <WarningIcon htmlColor="#FFCC00" />,
  },
  processing: {
    text: '対応中',
    icon: <HourglassFullIcon htmlColor="#007AFF" />,
  },
  processed: {
    text: '対応済み',
    icon: <CheckCircleIcon htmlColor="#34C759" />,
  },
} as const
export type GuardEventStatus = keyof typeof guardEventStatuses
export const eventStatuses = optionsConverter(guardEventStatuses)

export type GuardEvent = {
  id: string
  kind: GuardEventType
  date: number
  tenantId: string
  robotId: string
  tag?: GuardEventTag | null
  contactUserId?: string | null
  contactUserName?: string | null
  status: GuardEventStatus
  startDate?: number | null
  endDate?: number | null
  data: string
  resourceUrl: string
  preMovieUrl: string
  floorId: string
  location: string
}

export type EventsResponse = {
  status: string
  data: GuardEvent[]
}

export type EventsExportResponse = {
  status: string
  data: {
    downloadUrl?: string
  }
}
