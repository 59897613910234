import { CognitoUserPool } from "amazon-cognito-identity-js"

// ビルド時環境変数からAWS設定を取得(JSON)
const awsConfiguration = JSON.parse(process.env.REACT_APP_AWS_CONFIG as string)

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

export { awsConfiguration, userPool }
