import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { OperationCard } from './OperationCard'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { fetchOperations } from './operationsApi'
import { useAppSelector } from '../../app/hooks'
import { selectTenants } from '../tenants/tenantsSlice'
import { selectRobots } from '../robots/robotsSlice'
import { OperationLog, OperationSearchOptions } from './operationsTypes'

type Props = {
  searchOptions: OperationSearchOptions
  goBack?: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px auto',
    // display: 'flex',
    // flexDirection: 'column',
    // flexWrap: 'wrap',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  card: {
    margin: '10px auto',
    maxWidth: 900,
    flexGrow: 1,
  },
  loading: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:last-child': {
      margingTop: 10,
      paddingBottom: 10,
    },
  },
}))

export const OperationList: React.FC<Props> = ({ searchOptions, goBack }) => {
  const classes = useStyles()
  const [operations, setOperations] = useState<OperationLog[] | null>(null)
  const tenants = useAppSelector(selectTenants)
  const robots = useAppSelector(selectRobots)

  useEffect(() => {
    ;(async () => {
      setOperations(await fetchOperations(searchOptions))
    })()
  }, [searchOptions])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4">検索結果</Typography>
          <Typography variant="body1">
            {tenants.find((t) => t.id === searchOptions.tenantId)?.name}
          </Typography>
          {(searchOptions.startDate || searchOptions.endDate) && (
            <Typography variant="body1">
              {searchOptions.startDate?.toLocaleString()} ～{' '}
              {searchOptions.endDate?.toLocaleString()}
            </Typography>
          )}
          {(searchOptions.robotId?.length ?? 0) > 0 && (
            <Typography variant="body1">
              ロボット :{' '}
              {robots
                .filter((r) => searchOptions.robotId?.includes(r.id))
                .map((r) => r.name)
                .join(', ')}
            </Typography>
          )}
          {/* {
            searchOptions.type &&
            <Typography variant="body1">
              タイプ : { searchOptions.tag?.map(t => guardEventTags[t as GuardEventTag]?.text).join(', ') }
            </Typography>
          } */}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={goBack}
          >
            検索条件変更
          </Button>
          <div style={{ flexGrow: 1 }} />
        </CardActions>
      </Card>
      {operations ? (
        operations.length > 0 ? (
          operations.map((operation) => (
            <OperationCard
              operation={operation}
              className={classes.card}
              key={operation.operateAt + operation.deviceId + operation.operations}
            />
          ))
        ) : (
          <Card className={classes.card}>
            <CardContent className={classes.loading}>
              <Typography variant="h5">
                操作履歴が見つかりませんでした
              </Typography>
            </CardContent>
          </Card>
        )
      ) : (
        <Card className={classes.card}>
          <CardContent className={classes.loading}>
            <CircularProgress size={40} />
            <span style={{ height: 10 }}></span>
            <Typography variant="h5">操作履歴読込中…</Typography>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
