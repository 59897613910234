import { api } from '../Api'
import { apiEndpoint, camelizedParser, kebabize } from '../utils'
import type { LogsData, LogsExportOptions, LogsRequestOptions, LogsResponse } from './logsTypes'

export async function fetchLogs(options: LogsRequestOptions): Promise<LogsData> {
  const url = new window.URL('/maintenance/admin/cloudwatch-logs', apiEndpoint)
  Object.entries(options).forEach(([k,v]) => {
    if(!v) return
    url.searchParams.append(kebabize(k), v + '')
  })
  const res = await api.get(url.toString())
  const result: LogsResponse = camelizedParser(await res.text())
  return result.data
}
export async function exportLogs(options: LogsExportOptions): Promise<void> {
  const url = new window.URL('/maintenance/admin/cloudwatch-logs/export', apiEndpoint)
  Object.entries(options).forEach(([k,v]) => {
    if(!v) return
    url.searchParams.append(kebabize(k), v + '')
  })
  api.download(url.toString())
}
