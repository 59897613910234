import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import signinReducer from '../features/signin/signinSlice'
import tenantsReducer from '../features/tenants/tenantsSlice'
import robotsReducer from '../features/robots/robotsSlice'
import usersReducer from '../features/users/usersSlice'
import devicesReducer from '../features/devices/devicesSlice'
import floorsReducer from '../features/floors/floorsSlice'
import missionsReducer from '../features/missions/missionsSlice'
import soundsReducer from '../features/sounds/soundsSlice'
import compositionsReducer from '../features/compositions/compositionsSlice'
import mapsReducer from '../features/maps/mapsSlice'
import schedulesReducer from '../features/schedules/schedulesSlice'
import facesReducer from '../features/faces/facesSlice'
import presetsReducer from '../features/presets/presetsSlice'

export const store = configureStore({
  reducer: {
    signin: signinReducer,
    tenants: tenantsReducer,
    robots: robotsReducer,
    users: usersReducer,
    devices: devicesReducer,
    floors: floorsReducer,
    missions: missionsReducer,
    sounds: soundsReducer,
    compositions: compositionsReducer,
    maps: mapsReducer,
    schedules: schedulesReducer,
    faces: facesReducer,
    presets: presetsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
