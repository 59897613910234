import React, { useState } from 'react'
import { LogsSearchParams } from '../features/logs/logsTypes'
import LogsTable from '../features/logs/logTable'
import { LogSearch } from '../features/logs/logsSearchForm'

const LogsPage: React.FC = () => {
  const [search, setSearch] = useState<LogsSearchParams | null>(null)
  const [showResult, setShowResult] = useState(false)
  const onSubmit = (options: LogsSearchParams) => {
    setSearch(options)
    setShowResult(true)
  }
  const clearSearch = () => {
    setShowResult(false)
    // setSearch(null)
  }
  return showResult ? (
    <LogsTable searchParams={search!} onBack={clearSearch} />
  ) : (
    <LogSearch onSubmit={onSubmit} searchParams={search} />
  )
}

export default LogsPage
