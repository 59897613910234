import React, { SetStateAction, useState } from 'react'
import type { GridColDef, GridRowIdGetter } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import CreateSchedule from '../components/CreateSchedule'
import { FormValue } from '../components/Form/FormRows'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectTenantsForSelect } from '../features/tenants/tenantsSlice'
import {
  deleteScheduleAsync,
  selectSchedules,
  selectSchedulesStatus,
  updateScheduleAsync,
  importScheduleAsync,
} from '../features/schedules/schedulesSlice'
import { Checkbox } from '@material-ui/core'
import { days } from '../app/constants'
import { selectMissions } from '../features/missions/missionsSlice'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter } from '../features/utils'
// import { selectTenants } from '../features/tenants/tenantsSlice'
import { selectRobots } from '../features/robots/robotsSlice'
import { Schedule } from '../features/schedules/schedulesTypes'

const idGetter: GridRowIdGetter = (row) => {
  return row.scheduleId + '&' + row.tenantId + '&' + row.robotId
}

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const SchedulePage: React.FC = () => {
  // const classes = useStyles()
  const dispatch = useAppDispatch()
  const schedules = useAppSelector(selectSchedules)
  const [selectedSchedule, setSchedule] = useState<Schedule | null>(null)
  const schedulesStatus = useAppSelector(selectSchedulesStatus)
  const missions = useAppSelector(selectMissions)
  // const tenants = useAppSelector(selectTenants)
  const robots = useAppSelector(selectRobots)
  const [open, setOpen] = useState('')
  const tenants = useAppSelector(selectTenantsForSelect)

  const cols: GridColDef[] = [
    { field: 'scheduleId', headerName: 'ID', width: 200 },
    { field: 'name', headerName: '名前', width: 250 },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      minWidth: 200,
      // valueGetter({ value }) {
      //   return tenants.find((t) => t.id === value)?.name ?? value
      // },
    },
    {
      field: 'robotId',
      headerName: 'ロボット名',
      minWidth: 200,
      valueGetter({ value }) {
        return robots.find((r) => r.id === value)?.name ?? value
      },
    },
    {
      field: 'missionSetId',
      headerName: 'ミッションセット',
      width: 210,
      renderCell({ value }) {
        return missions.find((m) => m.id === value)?.name ?? value
      },
    },
    { field: 'startTime', headerName: '開始時刻', width: 135 },
    { field: 'endTime', headerName: '終了時刻', width: 135 },
    {
      field: 'week',
      headerName: '実行曜日',
      width: 160,
      renderCell({ value }) {
        return (value as string)
          .split(',')
          .filter((s) => s.length > 0)
          .map((d) => days[d.trim() as keyof typeof days])
          .join(', ')
      },
    },
    {
      field: 'execution',
      headerName: '有効',
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      renderCell({ value }) {
        return <Checkbox disabled checked={value as boolean} />
      },
    },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
  ]

  const setOpenAction = (actionName: SetStateAction<string>) => {
    setOpen(actionName)
  }

  const closeAction = () => {
    setOpen('')
  }

  // [CSPAX-100]
  const onImport = async (values: FormValue) => {
    const file = values['csv']
    const tenantId = values['tenantId'] as string
    if (file instanceof File) {
      await dispatch(importScheduleAsync([file, tenantId]))
      return true
    }
  }

  return (
    <>
      <TableComponent
        loading={schedulesStatus === 'loading'}
        // [CSPARX-100]
        createForm={{
          formTitle: 'CSVインポート',
          createText: '送信',
          FormRows: [
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
            },
            {
              type: 'file',
              accept: 'text/csv',
              name: 'csv',
              label: 'CSVファイル',
            },
          ],
        }}
        onImport={onImport}
        getRowId={idGetter}
        rows={schedules}
        columns={cols}
        pageSize={10}
        open={open}
        onOpen={setOpenAction}
        actions={[
          {
            name: 'addSchedule',
            color: 'primary',
            buttonText: '追加',
            dialogComponent: <CreateSchedule onClose={closeAction} />,
          },
          {
            name: 'editSchedule',
            color: 'primary',
            buttonText: '編集',
            disabled: !selectedSchedule,
            dialogComponent: (
              <CreateSchedule
                schedule={selectedSchedule}
                onClose={closeAction}
                onSubmit={async (schedule, robot, tenant) => {
                  schedule.tenantId = tenant
                  schedule.robotId = robot
                  if (selectedSchedule) {
                    schedule.updatedAt = selectedSchedule.updatedAt
                  }
                  const result = (await dispatch(updateScheduleAsync([schedule]))).payload as Schedule[]
                  if (result !== null) {
                    const newSchedule = result.find((s) => s.scheduleId === selectedSchedule?.scheduleId)
                    if(newSchedule)
                      setSchedule(newSchedule)
                    return true
                  } else {
                    return false
                  }
                }}
              />
            ),
          },
        ]}
        onDelete={async ([id]) => {
          const [scheduleId, tenantId, robotId] = (id as string).split('&')
          const schedule = schedules.find((s) => s.scheduleId === scheduleId)
          const robot = robots.find((r) => r.id === robotId)
          if (
            schedule &&
            (await confirm(
              'スケジュール削除',
              `本当に${tenantId} / ${robot?.name ?? robotId} / ${schedule.name
              }を削除してもよろしいですか?`,
            ))
          )
            dispatch(deleteScheduleAsync(scheduleId as string))
        }}
        onSelection={([row]) => {
          setSchedule(row as Schedule)
        }}
      />
    </>
  )
}

export default SchedulePage
