export type Preset = {
  tenantId: string
  robotId: string
  kind: string
  eventSettings: string
}

export type EventItems = {
  settingItem: string
  status: number
  notificate: boolean
  lastUpdateDate: number
  lastUpdateUser: string
}

export type EventSetting = {
  module: string,
  items: EventItems[]
}

export type KindName = {
  object1: String,
  object2: String,
  person: String
}

export const KindNameConv = {
  object1: '物体1',
  object2: '物体2',
  person:  '人',
} as KindName

export const KindTypeConv = {
  object1: 'noon1',
  object2: 'noon2',
  person: 'night',
} as KindName

export type PresetsPost = {
  tenantId: string
  robotId: string
  kind: string
  eventSettings: string
}

export type UpdatePresetsRequest = PresetsPost

export type PresetsResponse = {
  status: string
  data: Preset[]
}
