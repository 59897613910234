import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  createStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import { Autocomplete, AutocompleteClassKey } from '@material-ui/lab'
import type { FormSelectRowOption, FormSelectRowProps } from './FormRows'
import { ClassNameMap } from '@material-ui/styles'

type Props = {
  // name: string
  // label?: string
  // items: FormSelectRowOption[]
  // multiple?: boolean
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>
  onChange?: (name: string, values: string | string[] | null) => void
  textFieldProps?: Omit<TextFieldProps, 'onChange'>
  disabled?: boolean
} & Omit<FormSelectRowProps, 'type'>

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      margin: '10px 0 5px',
    },
  }),
)

const defaultItemGetter = (item: any): FormSelectRowOption => item

const SelectRow = (props: Props) => {
  const classes = useStyles()
  const { multiple, label, items, /* _items, */ name, textFieldProps, disabled, value, itemGetter: _itemGetter } = props
  const itemGetter = _itemGetter ?? defaultItemGetter
  const valueGetter = (rawItem: any): string => {
    const item = itemGetter(rawItem)
    return typeof item === 'string' ? item : item.value
  }
  const [selectedItem, setSelectItem] = useState((
    multiple ?
      (items).filter(i => value?.includes(valueGetter(i) ?? '')) ?? [] :
      (items).find(i => valueGetter(i) === value) ?? null
    ) as FormSelectRowOption[] | FormSelectRowOption | null
  )

  useEffect(() => {
    const availables = (items as any[]).map(i => valueGetter(i))
    if (selectedItem instanceof Array) {
      setSelectItem(selectedItem.filter(s => availables.includes(valueGetter(s))))
    } else if (selectedItem != null && !availables.includes(valueGetter(selectedItem))) {
      setSelectItem(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const getOptionLabel = (option: FormSelectRowOption) => {
    const item = itemGetter(option)
    return typeof item === 'string' ? item : item.name
  }
  const renderOption = (option: any) => <>{getOptionLabel(option)}</>
  
  const onChange = (_event: any, value: FormSelectRowOption | FormSelectRowOption[] | null) => {
    if (value == null) {
      props.onChange?.(name, null)
    } else {
      if (value instanceof Array) {
        const items = value.map(v => valueGetter(v))
        props.onChange?.(name, items)
      } else if(value) {
        const item = valueGetter(value)
        props.onChange?.(name, item)
      }
    }
    setSelectItem(value as any)
  }
  
  return (
    <Autocomplete
      className={classes.select}
      options={items as any}
      classes={props.classes}
      disabled={disabled ?? false}
      {...{ multiple, getOptionLabel, renderOption, onChange }}
      value={selectedItem}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" {...textFieldProps}/>
      )}
    />
  )
}

export default SelectRow
