import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import {
  createUserAsync,
  deleteUserAsync,
  selectUsersForShow,
  selectUsersStatus,
} from '../features/users/usersSlice'
import { selectTenantsForSelect } from '../features/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { RoleType, UserCreateRequest } from '../features/users/usersTypes'
import { selectCurrentRoleType } from '../features/signin/signinSlice'
import { FormSelectRowOption } from '../components/Form/FormRows'
import { Checkbox } from '@material-ui/core'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter, pathValidator } from '../features/utils'

const cols: GridColDef[] = [
  { field: 'userName', headerName: 'ID', width: 200 },
  { field: 'role', headerName: '権限', width: 100, sortable: false },
  {
    field: 'tenants',
    headerName: 'テナント',
    minWidth: 200,
    flex: 1,
    valueGetter({ value }) {
      return (value as string[] | undefined)?.join(', ')
    },
  },
  {
    field: 'isSystemAdmin',
    headerName: 'システム管理者',
    width: 135,
    disableColumnMenu: true,
    align: 'center',
    sortable: false,
    renderCell({ value }) {
      return <Checkbox checked={value as boolean} disabled />
    },
  },
  {
    field: 'createAt',
    headerName: '作成日時',
    minWidth: 160,
    valueGetter: cellTimeGetter,
  },
  {
    field: 'updatedAt',
    headerName: '更新日時',
    minWidth: 160,
    hide: true,
    valueGetter: cellTimeGetter,
  },
]

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const UserPage: React.FC = () => {
  // const classes = useStyles()
  // const [openCreateModal, setOpenCreateModal] = useState(false)
  const users = useAppSelector(selectUsersForShow)
  const tenants = useAppSelector(selectTenantsForSelect)
  const currentRole = useAppSelector(selectCurrentRoleType)
  const usersStatus = useAppSelector(selectUsersStatus)

  const dispatch = useAppDispatch()

  const roleItems: FormSelectRowOption[] = []
  switch (currentRole) {
    // @ts-ignore
    case 'system':
      roleItems.push({ value: 'system', name: 'システム管理者' })
      roleItems.push({ value: 'platform', name: 'プラットフォーム' })
    default:
      roleItems.push({ value: 'admin', name: '管理者' })
      roleItems.push({ value: 'guard', name: '警備員' })
  }

  return (
    <>
      <TableComponent
        loading={usersStatus === 'loading'}
        createForm={{
          formTitle: 'ユーザー作成',
          FormRows: [
            {
              type: 'text',
              name: 'userName',
              label: 'ユーザーID',
              validators: [
                pathValidator,
                {
                  re: /^.{0,128}$/,
                  helperText: '128文字以下である必要があります',
                }
              ],
            },
            {
              type: 'password',
              name: 'password',
              label: 'パスワード',
              defaultHelperText:
                '半角の大文字・小文字・数字・特殊文字(先頭末尾除くスペース含む)^$*.[]{}()?"!@#%&/\\,><\':;|_~`=+-それぞれ1文字以上含むで8文字以上99文字以下で設定してください',
              validators: [
                {
                  re: /^.{8,99}$/,
                  helperText: '8文字以上99文字以下である必要があります',
                },
                {
                  re: /[A-Z]/,
                  helperText: '大文字を含む必要があります',
                },
                {
                  re: /[a-z]/,
                  helperText: '小文字を含む必要があります',
                },
                {
                  re: /[0-9]/,
                  helperText: '数字を含む必要があります',
                },
                {
                  re: /[ ^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/,
                  helperText: '特殊文字(先頭末尾除くスペース含む)^$*.[]{}()?"!@#%&/\\,><\':;|_~`=+-を含む必要があります',
                },
                {
                  re: /[^\x20-\x7E]+/,//制御文字除く半角
                  rejection: true,
                  helperText: '半角文字以外は含められません',
                },
                {
                  re: /^ | $/,
                  rejection: true,
                  helperText: 'スペースは先頭及び末尾には含められません',
                }
              ],
            },
            {
              type: 'select',
              multiple: true,
              name: 'tenants',
              label: 'テナント',
              items: tenants ?? [],
            },
            {
              type: 'select',
              name: 'role',
              label: '権限',
              items: roleItems,
            },
          ],
        }}
        onSubmit={async (values) => {
          const user: UserCreateRequest = {
            userName: values['userName'] as string,
            roleList: (values['tenants'] as string[]).map((tenant) => ({
              tenant,
              role: (values['role'] === 'system'
                ? 'admin'
                : values['role']) as RoleType,
            })),
            password: values['password'] as string,
            isSystemAdmin: values['role'] === 'system',
          }
          return (
            (await (await dispatch(createUserAsync(user))).payload) !== null
          )
        }}
        onDelete={async ([id]) => {
          if (
            id &&
            (await confirm(
              'ユーザー削除',
              `本当に${id}を削除してもよろしいですか?`,
            ))
          )
            dispatch(deleteUserAsync(id as string))
        }}
        rows={users}
        getRowId={(row) => row.userName}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default UserPage
