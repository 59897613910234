import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
// import { createStyles, makeStyles } from '@material-ui/core'
import TableComponent from '../components/TableComponent'
import { useAppSelector } from '../app/hooks'
import {
  deleteDeviceAsync,
  selectdevices,
  selectdevicesStatus,
} from '../features/devices/devicesSlice'
import { useDispatch } from 'react-redux'
// import { selectTenants } from '../features/tenants/tenantsSlice'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter } from '../features/utils'

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     actionsContainer: {
//       width: '100%',
//       display: 'flex',
//       justifyContent: 'flex-end',
//       marginBottom: '10px',
//     },
//     action: {
//       margin: '10px',
//     },
//   }),
// )

const DevicePage: React.FC = () => {
  // const classes = useStyles()
  // const [openCreateModal, setOpenCreateModal] = useState(false)
  const devices = useAppSelector(selectdevices)
  // const tenants = useAppSelector(selectTenants)
  const devicesStatus = useAppSelector(selectdevicesStatus)
  const dispatch = useDispatch()

  const cols: GridColDef[] = [
    { field: 'deviceId', headerName: 'ID', minWidth: 200 },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
      // valueGetter({value}) {
      //   return tenants.find((t) => t.id === value)?.name ?? value
      // },
    },
    { field: 'deviceToken', headerName: 'DeviceToken', flex: 1 },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
  ]

  return (
    <>
      <TableComponent
        loading={devicesStatus === 'loading'}
        rows={devices}
        onDelete={async ([id]) => {
          if (id) {
            const device = devices.find((d) => d.deviceToken === id)
            if (
              device &&
              (await confirm(
                'デバイス削除',
                `${device.deviceId}を削除してもよろしいですか?`,
              ))
            )
              dispatch(deleteDeviceAsync([id as string, device.tenantId]))
          }
        }}
        columns={cols}
        getRowId={(row) => row.deviceToken}
        pageSize={10}
      />
    </>
  )
}

export default DevicePage
