import { Card, CircularProgress, TableCell, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  ColumnProps,
  Index,
  ScrollEventData,
  Table,
  TableCellProps,
  TableHeaderProps,
  TableProps,
} from 'react-virtualized'
import { formatDateTimeStr } from '../features/utils'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      // paddingTop: '0px',
      // paddingBottom: '0px',
      border: 'none',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    oddRow: {
      backgroundColor: '#F8F8F8'
    },
    // evenRow: {

    // },
    table: {
      position: 'relative',
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
        flip: false,
        paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      },
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
    overlayCard: {
      textAlign: 'center',
      padding: 10,
    },
    loading: {
      margin: '10px auto'
    },
    tableRow: {
      cursor: 'pointer',
      // padding: '0px 10px',
      borderBottom: '#DDDDDD solid 1px',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
    tableCell: {
      flex: 1,
      fontSize: '16px',
    },
    loadingCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&>*': {
        padding: '0px 5px'
      }
    },
    noClick: {
      cursor: 'initial',
    },
    noRows: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  })
})

export interface ColumnData extends ColumnProps {
  dataKey: string
  label: string
  numeric?: boolean
}

interface Row {
  index: number
}

const defaultProps = {
  headerHeight: 48,
  rowHeight: 0,
  overScanBefore: 1000,
  completed: true,
}

export interface MuiVirtualizedTableProps
  extends Omit<TableProps, keyof typeof defaultProps> {
  headerHeight?: number
  onRowClick?: () => void
  rowCount: number
  rowGetter: (row: Row) => any
  rowHeight?: number
  onScrollToBottom?: () => void
  overScanBefore?: number
  loading?: boolean
}

export const MuiVirtualizedTable: React.FC<MuiVirtualizedTableProps> = (
  props,
  ) => {
    const classes = useStyles()
    const { rowHeight, headerHeight, overScanBefore, loading, completed, rowCount, rowGetter, ...tableProps } = {
      ...defaultProps,
      ...props,
  }
  const table = useRef<Table>(null)
  const [cache] = useState(new CellMeasurerCache({
    fixedWidth: true,
    minHeight: rowHeight,
    defaultHeight: rowHeight,
  }))

  const getRowClassName = ({ index }: Row) => {
    const { onRowClick } = props
    
    return clsx(classes.tableRow, classes.row, {
      [classes.oddRow]: index % 2 === 1,
      // [classes.evenRow]: index % 2 === 0,
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    })
  }

  const renderDynamic = ({
    dataKey,
    parent,
    rowIndex,
    columnIndex,
    cellData,
  }: TableCellProps) => {
    if(cellData) {
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={dataKey}
          parent={parent}
          rowIndex={rowIndex}
        >
          <TableCell
            className={clsx(
              classes.tableCell,
              // classes.flexContainer,
              classes.noClick,
            )}
          >
            {(cellData as string).trim()}
          </TableCell>
        </CellMeasurer>
      )
    } else {
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={dataKey}
          parent={parent}
          rowIndex={rowIndex}
        >
          <TableCell
            className={clsx(
              classes.tableCell,
              classes.loadingCell,
              // classes.flexContainer,
              classes.noClick,
            )}
          >
            <CircularProgress size={20} />
            読込中...
          </TableCell>
        </CellMeasurer>
      )
    }
  }

  const headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns } = props

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns?.[columnIndex]?.numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
      </TableCell>
    )
  }

  const onScroll = ({
    scrollHeight,
    scrollTop,
    clientHeight,
  }: ScrollEventData) => {
    if (clientHeight + scrollTop >= scrollHeight - overScanBefore) {
      props.onScrollToBottom?.()
    }
  }

  const getRowHeight = ({index}: Index) => {
    const height = cache.getHeight(index, 0)
    return height + 10
  }

  const onResize = () => {
    table.current?.recomputeRowHeights()
    cache.clearAll()
  }

  return (
    <AutoSizer onResize={onResize}>
      {({ height, width }) => {
        return (
          <>
            <Table
              ref={table}
              height={height}
              width={width}
              // deferredMeasurementCache={cache}
              rowHeight={getRowHeight}
              gridStyle={{
                direction: 'inherit',
              }}
              noRowsRenderer={() => {
                return (
                  <div className={classes.noRows}>
                    一致するログが見つかりませんでした
                  </div>
                )
              }}
              onScroll={onScroll}
              headerHeight={headerHeight!}
              className={classes.table}
              {...tableProps}
              rowCount={rowCount + (completed ? 0 : 1)}
              rowGetter={
                (arg) => {
                  if(arg.index < rowCount) {
                    return rowGetter(arg)
                  } else {
                    return {}
                  }
                }
              }
              rowClassName={getRowClassName}
            >
              <Column
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: 0,
                  })
                }
                className={classes.flexContainer}
                width={200}
                cellRenderer={({ cellData, rowIndex }) => (
                  <TableCell
                    className={clsx(
                      classes.tableCell,
                      // classes.flexContainer,
                      classes.noClick,
                    )}
                    // height={getRowHeight({ index: rowIndex })}
                  >
                    {cellData ? formatDateTimeStr(new Date(cellData)) : ''}
                  </TableCell>
                )}
                label="日時"
                dataKey="timestamp"
              />
              <Column
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: 1,
                  })
                }
                className={classes.flexContainer}
                cellRenderer={renderDynamic}
                width={500}
                label="ログ"
                dataKey="message"
                flexGrow={1}
                flexShrink={1}
              />
            </Table>
            {
              loading && 
              <div style={{width, height}} className={classes.overlay}>
                <Card className={classes.overlayCard}>
                  <CircularProgress className={classes.loading}/>
                  <Typography color="textSecondary">読み込み中...</Typography>
                </Card>
              </div>
            }
          </>
        )
      }}
    </AutoSizer>
  )
}
