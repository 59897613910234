import {
  Button,
  DialogContent,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  DialogActions,
} from '@material-ui/core'
import { ChangeEventHandler, createRef, RefObject, useRef, useState } from 'react'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { MissionCard, MissionCardRefs } from './MissionCard'
import { ScheduleItem } from './types'
import { days } from '../../app/constants'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { createScheduleAsync } from '../../features/schedules/schedulesSlice'
import { formatTimeStr, max50Validator, parseTimeStr, pathValidator } from '../../features/utils'
import { CreateScheduleRequest, Schedule } from '../../features/schedules/schedulesTypes'
import SelectRow from '../Form/SelectRow'
import { selectRobots } from '../../features/robots/robotsSlice'
import { selectTenantsForSelect } from '../../features/tenants/tenantsSlice'
import { Robot } from '../../features/robots/robotsTypes'

const useStyles = makeStyles({
  card: {
    margin: '10px auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  addButton: {
    width: '50%',
  },
  timePicker: {
    width: '10rem',
    margin: '5px 5px',
  },
  weekdaySelectInput: {
    
  },
  scheduleRemarkColumn: {
    marginLeft: 10,
    width: 250
  },
  scheduleListColumn: {
    marginRight: 10,
    width: 380
  },
})

type Props = {
  schedule?: Schedule | null
  onSubmit?: (schedule :CreateScheduleRequest, robotId: string, tenantId: string) => Promise<boolean>
  onClose?: () => void
}

const randomStr = () =>
  window.btoa(
    String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16))),
  )

class MyDateUtil extends DateFnsUtils {
  constructor(a: { locale?: Locale }) {
    super(a)
    this.time24hFormat = 'HH:mm:ss'
  }
}

export default function CreateSchedule(props: Props) {
  const { schedule } = props

  const classes = useStyles()
  const robots = useAppSelector(selectRobots)
  const tenants = useAppSelector(selectTenantsForSelect)
  const dispatch = useAppDispatch()
  
  const [scheduleNameError, setScheduleNameError] = useState<string|null>(null)
  const [scheduleIdError, setScheduleIdError] = useState<string|null>(null)
  const [tenantError, setTenantError] = useState<string|null>(null)
  const [robotError, setRobotError] = useState<string|null>(null)
  
  const [scheduleName, setScheduleName] = useState(schedule?.name ?? '')
  const [scheduleId, setScheduleId] = useState(schedule?.scheduleId ?? '')
  const [tenant, setTenant] = useState(schedule?.tenantId ?? '')
  const [robot, setRobot] = useState(schedule?.robotId ?? '')
  const [execution, setExecution] = useState(schedule?.execution ?? false)

  const [filteredRobots, setFilteredRobots] = useState<Robot[]>(robots.filter(r => r.tenantId === tenant))
  const [missions, setMissions] = useState<ScheduleItem[]>([
    {
      id: randomStr(),
      mission: schedule?.missionSetId ?? '',
      startAt: parseTimeStr(schedule?.startTime) ?? null as unknown as Date,
      endAt: parseTimeStr(schedule?.endTime) ?? null,
    },
  ])
  // const [remarks, setRemarks] = useState('')
  const [weekDays, setWeekDays] = useState<string[]>(schedule?.week.split(',').filter(s => s.length > 0) ?? [])

  const scheduleItemsRef = useRef<RefObject<MissionCardRefs>[]>([])
  missions.forEach((_, i) => {
    scheduleItemsRef.current[i] = createRef<MissionCardRefs>()
  })

  
  const onScheduleItemChanged = (schedule: ScheduleItem) => {
    const idx = missions.findIndex((s) => schedule.id === s.id)
    if (0 <= idx) {
      missions.splice(idx, 1, schedule)
      setMissions([...missions])
    }
  }

  const scheduleNameValidate = (ev: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement> | string) => {
    const value = typeof ev === 'string' ? ev : ev.target.value
    if (value.length <= 0) {
      setScheduleNameError('必須項目です')
      return false
    }
    if (!max50Validator.re.test(value)) {
      setScheduleNameError(max50Validator.helperText)
      return false
    }
    setScheduleNameError(null)
    return true
  }
  const scheduleIdValidate = (ev: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement> | string) => {
    const value = typeof ev === 'string' ? ev : ev.target.value
    if (value.length <= 0) {
      setScheduleIdError('必須項目です')
      return false
    }
    if (!pathValidator.re.test(value)) {
      setScheduleIdError(pathValidator.helperText)
      return false
    }
    if (!max50Validator.re.test(value)) {
      setScheduleIdError(max50Validator.helperText)
      return false
    }
    setScheduleIdError(null)
    return true
  }
  const robotValidate = (ev: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement> | string) => {
    if ((typeof ev === 'string' ? ev : ev.target.value).length <= 0) {
      setRobotError('必須項目です')
      return false
    }
    setRobotError(null)
    return true
  }
  const tenantValidate = (ev: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement> | string) => {
    if ((typeof ev === 'string' ? ev : ev.target.value).length <= 0) {
      setTenantError('必須項目です')
      return false
    }
    setTenantError(null)
    return true
  }
  const scheduleNameChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setScheduleName(ev.target.value)
  }
  const scheduleIdChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setScheduleId(ev.target.value)
  }
  const robotChange = (
    _name: string,
    values: string | string[] | null,
  ) => {
    if (typeof values === 'string' || values == null) {
      setRobot(values ?? '')
    }
  }
  const tenantChange = (
    _name: string,
    values: string | string[] | null,
  ) => {
    if (typeof values === 'string' || values == null) {
      setTenant(values ?? '')
      setFilteredRobots(robots.filter(r => r.tenantId === values))
    }
  }
  const executionChanged: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setExecution(ev.target.checked)
  }
  const onWeekDaySelect = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => {
    const values = event.target.value
    setWeekDays(values as string[])
  }
  // const remarksChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
  //   setRemarks(ev.target.value)
  // }


  const saveSchedule = () => {
    if(
      [0 === scheduleItemsRef.current.filter((r) => !(r.current?.validate() ?? true)).length,
      scheduleNameValidate(scheduleName),
      scheduleIdValidate(scheduleId),
      /* robotSelectionValidate(selectedRobot) */].every(v => v)
    ) {
      const mission = missions[0]
      const schedule = {
        scheduleId: scheduleId,
        name: scheduleName,
        missionSetId: mission.mission,
        startTime: formatTimeStr(mission.startAt),
        endTime: mission.endAt ? formatTimeStr(mission.endAt) : undefined,
        week: weekDays.join(','),
        execution: execution,
        // remarks,
      } as CreateScheduleRequest
      if (props.onSubmit) {
        props.onSubmit(schedule, robot, tenant).then((res) => {
          if(res) props.onClose?.()
        })
      } else {
        dispatch(createScheduleAsync([schedule, robot, tenant])).then(() => {
          props.onClose?.()
        })
      }
    }
  }

  const robotItemGetter = ({name, id}: Robot) => ({name, value: id})

  return (
    <>
      <DialogContent>
          <TextField label="スケジュールID" disabled={!!schedule} value={scheduleId} onBlur={scheduleIdValidate} error={!!scheduleIdError} helperText={scheduleIdError} onChange={scheduleIdChange} margin="dense" fullWidth />
          <TextField label="スケジュール名" value={scheduleName} onBlur={scheduleNameValidate} error={!!scheduleNameError} helperText={scheduleNameError} onChange={scheduleNameChange} margin="dense" fullWidth />
          <SelectRow label="テナント" name="tenant" value={tenant} items={tenants} textFieldProps={{onBlur: tenantValidate, error: !!tenantError, helperText: tenantError}} onChange={tenantChange} />
          <SelectRow label="ロボット" name="robot" value={robot} /* _items={robots} */ itemGetter={robotItemGetter} items={filteredRobots} textFieldProps={{onBlur: robotValidate, error: !!robotError, helperText: robotError}} onChange={robotChange} />
          <MuiPickersUtilsProvider utils={MyDateUtil}>
            <MissionCard
              ref={scheduleItemsRef.current[0]}
              item={missions[0]}
              className={classes.card}
              classes={{ timePicker: classes.timePicker }}
              onChange={onScheduleItemChanged}
              robot={robot}
              // onDelete={i !== 0 ? onScheduleItemDelete : undefined}
              />
          </MuiPickersUtilsProvider>
          <FormControlLabel control={<Checkbox checked={execution} onChange={executionChanged} color="primary" />}
            label="有効"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel style={{ marginLeft: 10 }}>曜日</InputLabel>
            <Select
              variant="filled"
              value={weekDays}
              multiple
              onChange={onWeekDaySelect}
              renderValue={(selected) => {
                return (selected as (keyof typeof days)[]).map(k => days[k]).join(',')
              }}
            >
              {Object.entries(days).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  <Checkbox checked={weekDays.includes(key)} />
                  <ListItemText>{value}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField label="備考" value={remarks} onChange={remarksChange} multiline fullWidth /> */}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="default"
          onClick={props.onClose}
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.addButton}
          onClick={saveSchedule}
        >
          保存
        </Button>
      </DialogActions>
    </>
  )
}
