import React, { useState } from 'react'
import {
  TextField,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import type { DateTimeRowProps, FormFileRowProps, FormImageRowProps, FormSelectRowProps, FormTypes, FormValue } from './FormRows'
import FileInputRow from './FileInputRow'
import SelectRow from './SelectRow'
import { useFormStyles } from './styles'
import { twoLayerCopy } from '../../features/utils'
import DateTimeRow from './DateTimeRow'
import { zeroTime } from '../../app/constants'
import { confirm } from '../../features/dialogs/confirm'
import ImageRow from './ImageRow'

export type FormProps = {
  formTitle?: string
  createText?: string
  FormRows: FormTypes[number][]
  disables?: string[]
  onSubmit?: (values: FormValue) => (Promise<void> | void)
  onExport?: (values: FormValue) => (Promise<void> | void)
  onCancel?: () => void
}

type RowProps = FormTypes[number] & {
  flg?: boolean
  error?: boolean
  helperText?: string
}

const Form: React.FC<FormProps> = (props) => {
  const classes = useFormStyles()
  const [submitDisable, setSubmitDisable] = useState(false)
  const [formState, setFormState] = useState(props.FormRows.reduce((p, row) => {
    // 初期値の設定
    switch (row.type) {
      case 'file':
        // ファイルは代入できないため何もしない
        break
      case 'select':
        if (typeof row.value === 'string') {
          p[row.name] = row.value
        } else {
          p[row.name] = row.value ?? []
        }
        break
      case 'checkbox':
        p[row.name] = row.value ?? false
        break
      case 'radiogroup':
        p[row.name] = row.value ?? row.defaultValue
        break
      case 'datetime':
        p[row.name] = row.value ?? zeroTime
        break
      default:
        p[row.name] = row.value ?? ''
        break
    }
    return p
  }, {} as FormValue))
  const [rows, setRows] = useState<RowProps[]>(
    // 編集が本体に反映されないように2層コピー
    twoLayerCopy(props.FormRows).map((r) => {
      (r as RowProps).helperText = r.defaultHelperText;
      (r as any).disabled = props.disables?.includes(r.name)
      return r
    }),
  )

  // フォームタイトルの設定
  const header = props.formTitle ? (
    <Typography variant="h4" color="textSecondary">
      {props.formTitle}
    </Typography>
  ) : undefined
  
  const validate = (_row: RowProps): [boolean, string[]] => {
    // そのままrowを使うと参照が行方不明になるためrowsから取得
    const row = rows.find(r => r.name === _row.name)!

    const state = formState[row.name]
    const warns: string[] = []
    row.error =
    (row.required ?? true) &&
    (!(row.hidden && row.hidden(formState))) &&
    (state == null ||
      (typeof state === 'string' && state === '') ||
      (state instanceof Array && state.length === 0))
      if (row.error) {
      row.helperText = '必須項目です'
    } else if (
      'validators' in row &&
      row.validators &&
      typeof state === 'string'
      ) {
        const errors = row.validators.filter(
          (validator) => validator.rejection ? validator.re.test(state) : !validator.re.test(state),
        )
        // 確認文字列がある場合はエラーとして扱わない
        row.error = errors.filter(e => !e.warnText).length > 0
        // 注釈は改行非対応のため空白で連結
        row.helperText = errors.map((e) => e.helperText).join(' ')
        warns.push(...errors.map(e => e.warnText!).filter(e => e !== undefined))
      } else {
      row.helperText = row.defaultHelperText
    }
    setRows([...rows])
    return [row.error, warns]
  }
  
  // 入力値が変わった時にstateを更新
  const changeHandler = (
    name: string,
    value: string | string[] | File | Blob | boolean | Date | null,
  ) => {
    setFormState({ ...formState, [name]: value ?? undefined })
  }

  const fileChangeHandler = (name: string, file: File | Blob | null) => {
    const row = rows.find((r) => r.name === name)
    if (!row) return
    if (row.required && file == null) {
      row.error = true
      row.helperText = '必須項目です'
    } else {
      row.error = false
      row.helperText = row.defaultHelperText
    }
    changeHandler(name, file)
  }
  
  const textChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    if (typeof value === 'string') {
      const row = rows.find(r => r.name === ev.target.name)
      if(row) row.value = value
      changeHandler(ev.target.name, value)
    }
  }

  const checkChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.checked
    if (typeof value === 'boolean') {
      changeHandler(ev.target.name, value)
    }
  }

  const checkRadioGroupHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    if (typeof value === 'string') {
      const row = rows.find(r => r.name === ev.target.name)
      if(row) row.value = value
      changeHandler(ev.target.name, value)
    }
  }

  const generateState = async () => {
    // const closeLoading = loading('', (props.createText ?? '作成') + '中...', true)
    // 先にバリデーションをかける
    const [error, warns] = rows.reduce(([pb, pa], r) => {
      const [rb, ra] = validate(r)
      return [pb || rb, [...pa, ...ra]] as [boolean, string[]]
    }, [false, []] as [boolean, string[]])
    if (error) {
      return
    }
    // 許容不合格は確認ダイアログを表示
    if (warns.length > 0 && !await confirm('', warns.join('\n'))) {
      return
    }
  
    rows.forEach((row) => {
      if (row.type === 'select' && row.filter) {
        const valueGetter = (rawItem: any): string | undefined => {
          const item = row.itemGetter?.(rawItem) ?? rawItem
          return typeof item === 'string' ? item : item.value
        }
        const availables = row.filter(formState, row.items).map(r => valueGetter(r))
        const state = formState[row.name]
        if (state instanceof Array) {
          formState[row.name] = state.filter(s => availables.includes(s))
        } else if (typeof state === 'string' && !availables.includes(state)) {
          console.log('submitting', availables, state)
          formState[row.name] = undefined
        }    
      }
    })
    return formState
  }

  const submit = async () => {
    const formState = await generateState()
    if (formState) {
      setSubmitDisable(true)
      await props.onSubmit?.(formState)
      setSubmitDisable(false)
      // closeLoading()
    }
  }
  
  const xport = async () => {
    const formState = await generateState()
    if (formState) {
      setSubmitDisable(true)
      await props.onExport?.(formState)
      setSubmitDisable(false)
      // closeLoading()
    }
  }

  const cancel = props.onCancel ? (
    <Button variant="contained" color="inherit" onClick={props.onCancel}>
      キャンセル
    </Button>
  ) : null

  return (
    <Card className={classes.root} elevation={5}>
      <CardContent>
        {header}
        {rows.map(({hidden, ...row}, i) => {
          if (hidden && hidden(formState)) {
            if(formState[row.name]) {
              changeHandler(row.name, null)
            }
            return null
          }
          switch (row.type) {
            case 'file':
              return (
                <FileInputRow
                  {...(row as FormFileRowProps)}
                  key={row.name}
                  onChange={fileChangeHandler}
                  className={classes.row}
                />
              )
            case 'image':
              return (
                <ImageRow
                  {...(row as FormImageRowProps)}
                  key={row.name}
                  onChange={fileChangeHandler}
                  className={classes.row}
                />
              )
            case 'select':
              const { items: _items, filter, ...prop } = row
              const items = filter ? filter(formState, _items) : _items
              return (
                <SelectRow
                  {...(prop as FormSelectRowProps)}
                  items={items}
                  // _items={_items}
                  key={row.name}
                  onChange={changeHandler}
                  textFieldProps={{
                    onBlur: () => validate(row),
                    error: prop.error,
                    helperText: prop.helperText
                  }}
                />
              )
            case 'checkbox':
              return (
                <React.Fragment key={row.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={row.name}
                        checked={(formState[row.name] as boolean) ?? false}
                        onChange={checkChangeHandler}
                      />
                    }
                    label={row.label}
                  />
                  <br />
                </React.Fragment>
              )
            case 'radiogroup':
              return (
                <React.Fragment key={row.name}>
                  <RadioGroup
                    name={row.name}
                    defaultValue = {row.defaultValue}
                    onChange={checkRadioGroupHandler}
                  >
                  {
                    row.items.map(item => 
                      <FormControlLabel
                      control={
                        <Radio
                          value = {item.name}
                         />
                      }
                      label={item.label}
                      /> 
                    )  
                 }
                  </RadioGroup>
                  <br />
                </React.Fragment>
              )
            case 'datetime':
              return (
                <DateTimeRow key={row.name} {...(row as DateTimeRowProps)} onChange={changeHandler}/>
              )
            case 'password':
              return (
                <TextField
                  {...row}
                  fullWidth
                  key={row.name}
                  type="password"
                  onChange={textChangeHandler}
                  onBlur={() => validate(row)}
                  className={classes.row}
                />
              )
            case 'number':
              return (
                <TextField
                  type="number"
                  {...row}
                  fullWidth
                  key={row.name}
                  onChange={textChangeHandler}
                  onBlur={() => validate(row)}
                  className={classes.row}
                />
              )
            default:
              return (
                <TextField
                  {...row}
                  fullWidth
                  key={row.name}
                  onChange={textChangeHandler}
                  onBlur={() => validate(row)}
                  className={classes.row}
                />
              )
          }
        })}
      </CardContent>
      <CardActions className={classes.actions}>
        {cancel}
        <div className={classes.spacer} />
        {
          props.onExport &&
            <Button
              variant="contained"
              disabled={submitDisable}
              className={classes.saveButton}
              onClick={xport}
            >
              エクスポート
            </Button>
        }
        <Button
          variant="contained"
          color="primary"
          disabled={submitDisable}
          className={classes.saveButton}
          onClick={submit}
        >
          {props.createText ?? '作成'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Form
