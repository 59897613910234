import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { Tenant, TenantsResponse } from './tenantsTypes'

export async function fetchTenants(): Promise<TenantsResponse | undefined> {
  const res = await api.get('/maintenance/admin/tenant/list')
  const json = await res.text()
  return camelizedParser(json)
}

export async function createTenant(tenant: Tenant) {
  await api.post('/maintenance/admin/tenant-create', {
    body: kebabizedStringifier(tenant)
  })
}

export async function deleteTenant(tenantId: string) {
  await api.delete(`/maintenance/admin/tenant/${tenantId}`)
}
