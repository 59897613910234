import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { Composition, CompositionsResponse } from './compositionsTypes'

export async function fetchCompositions(): Promise<CompositionsResponse | undefined> {
  const res = await api.get('/maintenance/composition/list')
  const json = await res.text()
  return camelizedParser(json)
}

export async function createComposition(composition: Composition) {
  const res = await api.post('/maintenance/composition-create', {
    body: kebabizedStringifier(composition)
  })
  return res.json()
}

export async function deleteComposition(compositionId: string) {
  await api.delete(`/maintenance/composition/${compositionId}`)
}
