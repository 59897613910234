import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { actuatorsMap, onStates, OperationLog } from './operationsTypes'
import { MyIcon } from '../../components/MyIcon'
import { useAppSelector } from '../../app/hooks'
import { selectSchedules } from '../schedules/schedulesSlice'
// import { useAppSelector } from '../../app/hooks'
// import { selectRobots } from '../robots/robotsSlice'

type Props = {
  operation: OperationLog
  className?: string
  style?: CSSProperties
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  cardBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    flexGrow: 1,
  },
  cardHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  cardTitle: {
    flexShrink: 0,
    flexGrow: 1,
  },
  icon: {
    margin: 'auto 5px'
  },
  actuator: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    padding: '0px 5px',
    width: 'fit-content',
    color: 'white',
  }
}))

export const OperationCard: React.FC<Props> = ({ operation, className, style }) => {
  const classes = useStyles()
  // const robots = useAppSelector(selectRobots)
  const schedules = useAppSelector(selectSchedules)

  const actuator = actuatorsMap.find(a => operation.actuators.startsWith(a.prefix))
  const onOp = onStates.find(s => operation.operations === s) ? true : false
  const schedule = schedules.find(s => s.scheduleId === operation.scheduleId)

  let iconColor = 'gray'
  if (actuator) {
    if(actuator.when && operation.operations in actuator.when) {
      iconColor = actuator.when[operation.operations]
    } else if (onOp) {
      iconColor = actuator.color
    }
  } else {
    iconColor = 'darkgray'
  }

  return (
    <Card className={clsx(classes.card, className)} style={style}>
      <Box className={classes.cardBox}>
        <CardContent className={classes.boxContainer}>
          <div className={classes.cardHeader}>
            <Typography variant="h5" className={classes.cardTitle}>
              {new Date(operation.operateAt).toLocaleString()}
            </Typography>
          </div>
          <div className={classes.actuator} style={{ background: iconColor }} title="操作機器ID">
            {
              actuator &&
              <MyIcon type={actuator.icon} className={classes.icon} color="white" />
            }
            <Typography variant="h6">
              {operation.actuators}
            </Typography>
          </div>
          <Typography variant="body1">ユーザーID: {operation.userId}</Typography>
          <Typography variant="body1">デバイスID: {operation.deviceId}</Typography>
          <Typography variant="body1">操作: {operation.operations}</Typography>
          {
            operation.jsonData && operation.jsonData.length !== 0 &&
              <Typography variant="body1">
                位置: {operation.jsonData}
              </Typography> 
          }          {
            operation.scheduleId && operation.scheduleId.length !== 0 &&
              <Typography variant="body1">
                スケジュール: {(schedule != null ? `${schedule.name} (${schedule.scheduleId})` : `${operation.scheduleId}`)}
              </Typography> 
          }
        </CardContent>
      </Box>
    </Card>
  )
}
