import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createRobot, deleteRobot, fetchRobots } from './robotsApi'
import type { Robot, RobotCreateRequest } from './robotsTypes'


export interface RobotsState {
  robots: Robot[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: RobotsState = {
  robots: [],
  status: 'unload',
};

export const fetchRobotsAsync = createAsyncThunk(
  'robots/fetch',
  async () => {
    const response = await fetchRobots().catch((err) => {
      console.log('robots fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const createRobotAsync = createAsyncThunk('robots/create', async (args: [RobotCreateRequest, string]) => {
  await createRobot(...args)
  return (await fetchRobots())?.data
})

export const deleteRobotAsync = createAsyncThunk('robots/delete', async (args: [string, string]) => {
  await deleteRobot(...args)
  return (await fetchRobots())?.data
})


export const robotsSlice = createSlice({
  name: 'robots',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRobotsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRobotsAsync.fulfilled, (state: RobotsState, action) => {
        state.status = 'loaded';
        state.robots = action.payload
      })
      .addCase(createRobotAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createRobotAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.robots = action.payload ?? state.robots
      })
      .addCase(deleteRobotAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteRobotAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.robots = action.payload ?? state.robots
      })
  },
});


export const selectRobots = (state: RootState) => state.robots.robots;
export const selectRobotsStatus = (state: RootState) => state.robots.status
export const selectRobotsForSelect = (state: RootState) => state.robots.robots.map(({id: value, name}) => ({name, value}));
export const selectRobotsForSelectIn = (tenantId: string) => (state: RootState) => state.robots.robots.filter(r => r.tenantId === tenantId).map(({id: value, name}) => ({name, value}));

export default robotsSlice.reducer;
