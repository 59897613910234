import {
  Card,
  CardContent,
} from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ScheduleItem } from './types'
import SelectRow from '../Form/SelectRow'
import { useSelector } from 'react-redux'
import { selectMissions } from '../../features/missions/missionsSlice'
import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from '../../app/hooks'
import { selectRobots } from '../../features/robots/robotsSlice'

type Props = {
  item: ScheduleItem
  className?: string
  classes?: {
    timePicker?: string
  }
  robot?: string
  onChange?: (item: ScheduleItem) => void
  onDelete?: (item: ScheduleItem) => void
}

export type MissionCardRefs = {
  validate: () => boolean
}

const useStyles = makeStyles((theme) => ({
  missionSelect: {
    flexGrow: 1,
  },
}))

const RefMissionCard: React.ForwardRefRenderFunction<MissionCardRefs, Props> = (props, ref) => {
  const [mission, setMission] = useState(props.item.mission)
  const [startAt, setStartAt] = useState(props.item.startAt)
  const [endAt, setEndAt] = useState(props.item.endAt)
  const classes = useStyles()

  const [missionError, setMissionError] = useState<string|undefined>(undefined)
  const [startError, setStartError] = useState<string|undefined>(undefined)
  const [endError, setEndError] = useState<string|undefined>(undefined)

  const missions = useSelector(selectMissions)
  // const filteredMissions = missions.filter(m => m.robotId === props.robot)
  const robots = useAppSelector(selectRobots)
  const robot = robots.find(m => m.id === props.robot)
  const jsonData = robot?.robotCompositions?.jsonData
  const selectedRobotDriveUnit = jsonData ? JSON.parse(jsonData)?.common?.drive_unit : null
  const filteredMissions = selectedRobotDriveUnit === 'kg' ? missions.filter(m => m.robotId === props.robot && m.kgTaskSetId) : missions.filter(m => m.robotId === props.robot && !m.kgTaskSetId)

  const validateMission = (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | string): boolean => {
    setMissionError(undefined)
    if((typeof ev !== 'string' && ev.target.value.length <= 0) || (typeof ev === 'string' && ev.length <= 0)) {
      setMissionError('必須項目です')
      return false
    }
    return true
  }
  const changeMission = (_name: string, value: string | string[] | null) => {
    if (typeof value === 'string' || value == null) {
      setMission(value ?? '')
      props.onChange?.({
        id: props.item.id,
        mission: value ?? '', startAt, endAt,
      })
    }
  }
  const validateTime = (startAt: Date | null, endAt: Date | null): boolean => {
    /*
    if (startAt && endAt && endAt < startAt) {
      setStartError("終了時刻より遅くすることはできません")
      setEndError("開始時刻より早くすることはできません")
      return false
    }
    */
    if (!startAt) {
      setStartError("必須項目です")
      setEndError(undefined)
      return false
    }
    setStartError(undefined)
    setEndError(undefined)
    props.onChange?.({
      id: props.item.id,
      mission, startAt, endAt,
    })
    return true
  }
  const setStartTime = (time: Date | null) => {
    if (time == null) {
      setStartError("必須項目です")
      return
    }
    validateTime(time, endAt)
    setStartAt(time)
  }
  const setEndTime = (time: Date | null) => {
    // if (time == null) {
    //   setEndError("必須項目です")
    //   return
    // }
    validateTime(startAt, time)
    setEndAt(time)
  }

  // const onDeleteClick = () => {
  //   props.onDelete?.(props.item)
  // }

  useImperativeHandle(ref, () => ({
    validate(): boolean {
      return [
        validateMission(mission),
        validateTime(startAt, endAt)
      ].every(b => b)
    }
  }))

  return (
    <Card className={props.className}>
      <CardContent>
        <div style={{ display: 'flex' }}>
          <SelectRow
            name="mission"
            label="ミッションセット"
            required
            textFieldProps={{
              onBlur:validateMission,
              error: !!missionError,
              helperText: missionError,
              value: mission,
            }}
            value={mission}
            // _items={missions}
            items={filteredMissions}
            itemGetter={({id, name}) => ({name, value: id})}
            classes={{ root: classes.missionSelect }}
            onChange={changeMission}
            />
          <div />
          {/* <Button onClick={onDeleteClick} disabled={!props.onDelete}>
            <HighlightOff />
          </Button> */}
        </div>
        <KeyboardTimePicker
          className={props.classes?.timePicker}
          margin="normal"
          label="開始時刻"
          error={!!startError}
          helperText={startError}
          ampm={false}
          value={startAt}
          views={['hours', 'minutes', 'seconds']}
          onChange={setStartTime}
          />
        <KeyboardTimePicker
          className={props.classes?.timePicker}
          margin="normal"
          label="終了時刻"
          error={!!endError}
          helperText={endError}
          ampm={false}
          value={endAt}
          views={['hours', 'minutes', 'seconds']}
          onChange={setEndTime}
        />
      </CardContent>
    </Card>
  )
}

export const MissionCard = forwardRef(RefMissionCard)
