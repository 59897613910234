import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { deleteMap, fetchMapFromRobot, fetchMaps, uploadMap } from './mapsApi'
import type { Map } from './mapsTypes'

export interface mapsState {
  maps: Map[]
  status: 'unload' | 'loading' | 'loaded'
}

const initialState: mapsState = {
  maps: [],
  status: 'unload',
}

export const fetchMapsAsync = createAsyncThunk('maps/fetch', async () => {
  const response = await fetchMaps().catch((err) => {
    console.log('maps fetch error' + err)
  })
  if (response) {
    return response.data ?? []
  } else {
    return []
  }
})

export const uploadMapAsync = createAsyncThunk(
  'maps/upload',
  async ([mapImage, tenantId]: [File, string]) => {
    await uploadMap(mapImage, tenantId)
    return (await fetchMaps())?.data
  },
)

export const fetchMapFromRobotAsync = createAsyncThunk(
  'maps/fetchFromRobot',
  async ([mapName, tenantId, robotId]: [string, string, string]) => {
    await fetchMapFromRobot(mapName, tenantId, robotId)
    return
  },
)

export const deleteMapAsync = createAsyncThunk(
  'maps/delete',
  async ([mapName, tenantId]: [string, string]) => {
    await deleteMap(mapName, tenantId)
    return (await fetchMaps())?.data
  },
)

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchMapsAsync.fulfilled, (state: mapsState, action) => {
        state.status = 'loaded'
        state.maps = action.payload
      })
      .addCase(deleteMapAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteMapAsync.fulfilled, (state: mapsState, action) => {
        state.status = 'loaded'
        state.maps = action.payload ?? state.maps
      })
      .addCase(uploadMapAsync.fulfilled, (state, action) => {
        state.maps = action.payload ?? state.maps
      })
  },
})

export const selectMaps = (state: RootState) => state.maps.maps
export const selectMapsStatus = (state: RootState) => state.maps.status

export default mapsSlice.reducer
