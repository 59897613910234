import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { EventExportOptions, EventsSearchOptions, GuardEvent, GuardEventStatus, guardEventStatuses, GuardEventSubKind, guardEventSubKinds, GuardEventTag, guardEventTags, GuardEventType, guardEventTypes } from './eventsTypes'
import { EventCard } from './EventCard'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { exportEventsNext, fetchEvents } from './eventsApi'
import Form from '../../components/Form'
import { useAppSelector } from '../../app/hooks'
import { selectTenants } from '../tenants/tenantsSlice'
import { selectRobots } from '../robots/robotsSlice'

type Props = {
  searchOptions: EventsSearchOptions
  goBack?: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px auto',
    // display: 'flex',
    // flexDirection: 'column',
    // flexWrap: 'wrap',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  card: {
    margin: '10px auto',
    maxWidth: 900,
    flexGrow: 1,
  },
  loading: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:last-child': {
      margingTop: 10,
      paddingBottom: 10
    }
  }
}))

export const EventList: React.FC<Props> = ({ searchOptions, goBack }) => {
  const classes = useStyles()
  const [events, setEvents] = useState<GuardEvent[] | null>(null)
  const tenants = useAppSelector(selectTenants)
  const robots = useAppSelector(selectRobots)
  const [openExport, setOpenExport] = useState(false)

  useEffect(() => {
    ;(async () => {
      setEvents(await fetchEvents(searchOptions))
    })()
  }, [searchOptions])

  const handleExport = () => {
    setOpenExport(true)
  }

  const handleCloseExport = () => {
    setOpenExport(false)
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4">検索結果</Typography>
          <Typography variant="body1">
            {tenants.find(t => t.id === searchOptions.tenantId)?.name}
          </Typography>
          {
            (searchOptions.startDate || searchOptions.endDate) &&
            <Typography variant="body1">
              { searchOptions.startDate?.toLocaleString() } ～ { searchOptions.endDate?.toLocaleString() }
            </Typography>
          }
          {
            (searchOptions.robotId?.length ?? 0) > 0 &&
            <Typography variant="body1">
              ロボット : { robots.filter(r => searchOptions.robotId?.includes(r.id)).map(r => r.name).join(', ') }
            </Typography>
          }
          {
            (searchOptions.tag?.length ?? 0) > 0 &&
            <Typography variant="body1">
              タグ : { searchOptions.tag?.map(t => guardEventTags[t as GuardEventTag]?.text).join(', ') }
            </Typography>
          }
          {
            (searchOptions.eventKind?.length ?? 0) > 0 &&
            <Typography variant="body1">
              イベント種別 : { searchOptions.eventKind?.map(e => guardEventTypes[e as GuardEventType]?.text).join(', ') }
            </Typography>
          }
          {
            (searchOptions.eventSubKind?.length ?? 0) > 0 &&
            <Typography variant="body1">
              イベント詳細種別 : { searchOptions.eventSubKind?.map(e => guardEventSubKinds[e as GuardEventSubKind]?.text).join(', ') }
            </Typography>
          }
          {
            (searchOptions.status?.length ?? 0) > 0 &&
            <Typography variant="body1">
              対応状況 : { searchOptions.status?.map(s => guardEventStatuses[s as GuardEventStatus]?.text).join(', ') }
            </Typography>
          }
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={goBack}
          >
            検索条件変更
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button variant="contained" color="primary" onClick={handleExport}>
            エクスポート
          </Button>
          <Dialog open={openExport} onClose={handleCloseExport}>
            <Form
              formTitle="エクスポートファイル選択"
              createText="エクスポート"
              onCancel={handleCloseExport}
              FormRows={[
                {
                  type: 'checkbox',
                  name: 'csv',
                  label: 'CSV',
                  value: true
                },
                {
                  type: 'checkbox',
                  name: 'imageOrigin',
                  label: 'フレーム無し検知画像',
                },
                {
                  type: 'checkbox',
                  name: 'imageFrame',
                  label: 'フレーム付き検知画像',
                },
                {
                  type: 'checkbox',
                  name: 'movie',
                  label: 'プリ動画',
                },
              ]}
              onSubmit={(values) => {
                exportEventsNext(searchOptions, values as EventExportOptions)
              }}
            />
          </Dialog>
        </CardActions>
      </Card>
      {
        events ? 
          events.length > 0 ?
            events.map((event) => (
              <EventCard event={event} className={classes.card} key={event.id} />
            ))
            :
            <Card className={classes.card}>
              <CardContent className={classes.loading}>
                <Typography variant="h5">
                  イベントが見つかりませんでした
                </Typography>
              </CardContent>
            </Card>
          :
          <Card className={classes.card}>
            <CardContent className={classes.loading}>
              <CircularProgress size={40}/>
              <span style={{height: 10}}></span>
              <Typography variant="h5">
                イベント一覧読込中…
              </Typography>
            </CardContent>
          </Card>
      }
    </div>
  )
}
