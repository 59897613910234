import { api } from '../Api'
import { apiEndpoint, camelizedParser, kebabize } from '../utils'
import {
  ErrorLog,
  ErrorSearchOptions,
  ErrorsResponse,
} from './errorsTypes'

const queryInjector = (url: URL, options: ErrorSearchOptions): URL => {
  Object.entries(options).forEach(([k,v]) => {
    if(v == null || v === '' || k === 'tenantId') return
    if (v instanceof Date) {
      url.searchParams.append(kebabize(k), Math.floor(v.getTime() / 1000) + '')
    } else {
      url.searchParams.append(kebabize(k), v + '')
    }
  })
  return url
}

export async function fetchErrors(options: ErrorSearchOptions): Promise<ErrorLog[]> {
  const url = new window.URL(`/maintenance/tenant/${options.tenantId}/admin/error-list`, apiEndpoint)
  queryInjector(url, options)
  const res = await api.get(url.pathname + url.search)
  const json = camelizedParser<ErrorsResponse>(await res.text())

  return json.data ?? []
}
