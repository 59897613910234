import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { userPool } from '../awsConfig'
import { api, LoginResponseData } from '../Api'


export type PasswdCredential = {
  userId: string
  password: string
}

let intervalId: NodeJS.Timeout | undefined

export function trySigninWithRefreshToken() {
  return new Promise<LoginResponseData>((resolve, reject) => {
    // 過去ログインしたユーザーを取得
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) {
      reject()
      return
    }
    cognitoUser.getSession(async (error: any, session: CognitoUserSession) => {
      if (error) {
        reject(error)
        return
      }
      const idToken = session.getIdToken().getJwtToken()
      if (!intervalId) intervalId = setInterval(trySigninWithRefreshToken, 600000)
      const res = await api.login(idToken)
      if ('userName' in res) {
        resolve(res)
      } else {
        reject()
      }
    })
  })
}

export function signin(passwdCredential: PasswdCredential) {
  return new Promise<LoginResponseData>((resolve, reject) => {
    // setTimeout(() => resolve({ data: amount }), 500)
    const authenticationDetails = new AuthenticationDetails({
      Username: passwdCredential.userId,
      Password: passwdCredential.password,
    })
    const cognitoUser = new CognitoUser({
      Username: passwdCredential.userId,
      Pool: userPool,
    })
    
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        const idToken = result.getIdToken().getJwtToken()
        intervalId = setInterval(trySigninWithRefreshToken, 600000)
        const res = await api.login(idToken)
        if ('userName' in res) {
          resolve(res)
        } else {
          reject()
        }
      },
      onFailure: (err) => {
        console.error(err)
        reject()
      },
    })
  })
}

export async function signOut() {
  // cognitoからログアウト
  userPool.getCurrentUser()?.signOut()
  // アプリケーションサーバからログアウト
  await api.post('/maintenance/logout', {}, true)
}
