import { makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles(() => ({
  body: {
    whiteSpace: 'pre-line',
  },
  centering: {
    padding: '10px',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progress: {
    margin: '10px auto'
  }
}))
