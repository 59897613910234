import { createStyles, makeStyles } from '@material-ui/styles'
import { Paper, TextField, Button, Dialog, PropTypes } from '@material-ui/core'
import { DataGrid, DataGridProps, GridFilterModelState, GridPageChangeParams, GridRowData, GridRowId } from '@material-ui/data-grid'
import React, { useState } from 'react'
import Form, { FormProps } from './Form'
import { localeText } from '../app/constants'
import { FormValue } from './Form/FormRows'
import { renderCellExpand } from './GridCell'
import { twoLayerCopy } from '../features/utils'

export type TableAction = {
  name: string
  color?: PropTypes.Color
  disabled?: boolean
  buttonText: string
  dialogComponent: JSX.Element
}

type Props =
  | {
      createForm?: Omit<FormProps, 'onCancel' | 'onSubmit' | 'disables'>
      enableSearch?: boolean
      onSubmit?: (values: FormValue) => Promise<boolean | undefined>
      onDelete?: (row: GridRowId[]) => void
      onOpen?: React.Dispatch<React.SetStateAction<string>>
      open?: string
      actions?: TableAction[]
      edit?: {
        title?: string
        disables?: string[]
        editRowConverter?: (row: GridRowData) => Object // {field: string, value: any}[]
        onEdit: (values: FormValue, prev: any) => Promise<boolean | undefined>
      }
      onSelection?: (rows: (GridRowData|undefined)[]) => void 
      onImport?: (values: FormValue) => Promise<boolean | undefined>  // [CSPARX-100]
    } & Omit<DataGridProps, 'onSelectionModelChange'>

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '10px auto',
    },
    actionsContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'flex-end',
      marginBottom: '10px',
    },
    spacer: {
      flexGrow: 1,
    },
    annotations: {
      padding: '10px 5px 5px',
    },
    action: {
      margin: '10px',
    },
    dialogPaper: {
      minWidth: 400,
      maxWidth: 800,
    },
  }),
)

const defaultEditConverter = (row: GridRowData): any => {
  return row
}

const TableComponent: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [selectedModelIds, setSelectedModelIds] = useState([] as GridRowId[])
  const [pageSize, setPageSize] = useState(50)

  let [openAction, setOpenAction] = useState('')
  if (props.open)   openAction = props.open
  if (props.onOpen) setOpenAction = props.onOpen

  // @ts-ignore
  const { createForm, enableSearch, onSubmit, onDelete, onSelection, onImport, edit, rows, columns, ...gridProps } = props

  const onSubmitThenClose = async (values: FormValue) => {
    if(await onSubmit?.(values)) {
      setOpenAction('')
    }
  }

  // [CSPARX-100]
  const onImportThenClose = async (values: FormValue) => {
    if(await onImport?.(values)) {
      setOpenAction('')
    }
  }

  const onCancel = () => {
    setOpenAction('')
  }
  
  const [filterModel, setFilterModel] = useState<GridFilterModelState>({
    items: []
  })

  const onFilterModelChange = (model: GridFilterModelState) => {
    setFilterModel(model)
  }

  const handleSearchChanged: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilterModel({
      items: columns.filter(c => c.disableColumnMenu !== true).map((c, i) => ({
        id: i,
        columnField: c.field,
        operatorValue: 'contains',
        value: event.target.value,
      })),
      // @ts-ignore
      linkOperator: 'or'
    })
  }

  const idGetter = (row: GridRowData) => {
    return props.getRowId?.(row) ?? row.id
  }

  const handleSelectionChanged = (ids: GridRowId[]) => {
    setSelectedModelIds(ids)
    onSelection?.(ids.map(id => props.rows.find(r => idGetter(r) === id)))
  }

  const pageSizeChange = (params: GridPageChangeParams) => {
    setPageSize(params.pageSize)
  }

  const searchBox =
  enableSearch ?? true ? (
    <TextField
    variant="outlined"
    label="検索"
    onChange={handleSearchChanged}
    margin="dense"
    className={classes.action}
    />
    ) : null

  const actions: JSX.Element[] = []
  if (props.actions) {
    actions.push(
      ...props.actions.map(({ dialogComponent, color, buttonText, disabled }) => (
        <React.Fragment key={buttonText}>
          <Button
            variant="contained"
            color={color}
            disabled={disabled}
            className={classes.action}
            onClick={() => setOpenAction(buttonText)}
          >
            {buttonText}
          </Button>
          <Dialog
            open={buttonText === openAction}
            onClose={onCancel}
            classes={{ paper: classes.dialogPaper }}
          >
            {dialogComponent}
          </Dialog>
        </React.Fragment>
      )),
    )
  }

  //if (createForm) {    // [CSPARX-100] Comment out
  if (onSubmit !== undefined && createForm) {   // [CSPARX-100] add
      actions.push(
      <React.Fragment key="_add">
        <Button
          variant="contained"
          className={classes.action}
          color="primary"
          onClick={() => {
            setOpenAction('_追加')
          }}
        >
          追加
        </Button>
        <Dialog open={openAction === '_追加'} onClose={onCancel}>
          <Form {...createForm} onCancel={onCancel} onSubmit={onSubmitThenClose} />
        </Dialog>
      </React.Fragment>,
    )
  }
  if (edit !== undefined && createForm) {
    const selectedModel = rows.find(r => (gridProps.getRowId?.(r) || r.id) === selectedModelIds[0])
    const formRows = twoLayerCopy(createForm.FormRows)
    if (selectedModel) {
      const converted = edit.editRowConverter?.(selectedModel) ?? defaultEditConverter(selectedModel)
      formRows.forEach(fr => {
        fr.value = converted[fr.name] ?? ''
      })
    }
    const onEditedThenClose = async (values: FormValue) => {
      if(await edit.onEdit?.(values, selectedModel)) {
        setOpenAction('')
      }
    }  
    actions.push(
      <React.Fragment key="_edit">
        <Button
          variant="contained"
          className={classes.action}
          color="primary"
          disabled={selectedModelIds.length === 0}
          onClick={() => {
            setOpenAction('_編集')
          }}
        >
          編集
        </Button>
        <Dialog open={openAction === '_編集'} onClose={onCancel}>
          <Form {...createForm} disables={edit.disables} formTitle={edit.title} createText="保存" FormRows={formRows} onCancel={onCancel} onSubmit={onEditedThenClose} />
        </Dialog>
      </React.Fragment>,
    )
  }
  if (onDelete !== undefined) {
    actions.push(
      <Button
        key="_delete"
        variant="contained"
        className={classes.action}
        color="secondary"
        disabled={selectedModelIds.length === 0}
        onClick={() => {
          onDelete(selectedModelIds)
        }}
      >
        削除
      </Button>,
    )
  }
  // [CSPARX-100]
  if (onImport !== undefined && createForm) {
      actions.push(
      <React.Fragment key="_import">
        <Button
          variant="contained"
          className={classes.action}
          color="primary"
          onClick={() => {
            setOpenAction('_インポート')
          }}
        >
          インポート
        </Button>
        <Dialog open={openAction === '_インポート'} onClose={onCancel}>
          <Form {...createForm} onCancel={onCancel} onSubmit={onImportThenClose} />
        </Dialog>
      </React.Fragment>,

    )
  }

  columns.forEach(e => {
    if (!e.renderCell) {
      e.renderCell = renderCellExpand
    }
  })

  return (
    <div className={classes.root}>
      <Paper className={classes.actionsContainer}>
        {searchBox}
        <div className={classes.spacer} />
        {actions}
      </Paper>
      <DataGrid
        {...gridProps}
        onFilterModelChange={onFilterModelChange}
        filterModel={filterModel}
        columns={columns}
        rows={rows}
        disableMultipleSelection={true}
        style={{ height: '650px' }}
        onSelectionModelChange={handleSelectionChanged}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={pageSizeChange}
        localeText={localeText}
      />
    </div>
  )
}

export default TableComponent
