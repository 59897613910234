import React, { SetStateAction, useState } from 'react'
import type { GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { camelizedParser } from '../features/utils'
import { selectRobots } from '../features/robots/robotsSlice'
import { selectTenants } from '../features/tenants/tenantsSlice'
import { selectPresets, selectPresetsStatus, updatePresetsAsync } from '../features/presets/presetsSlice'
import { EventSetting, KindName, KindNameConv, Preset } from '../features/presets/presetsTypes'
import EditPreset from '../components/EditPreset'
import { JsonComposition } from '../features/compositions/compositionsTypes'

const PresetsPage: React.FC = () => {
  
  const dispatch = useAppDispatch()

  const robots = useAppSelector(selectRobots)
  const tenants = useAppSelector(selectTenants)
  const presets = [...useAppSelector(selectPresets)].sort((a, b) => {
    return ((a.tenantId+a.robotId+a.kind) < (b.tenantId+b.robotId+b.kind)) ? -1 : 1;
  });
  const presetsStatus = useAppSelector(selectPresetsStatus)
  const [selectedPreset, setPresets] = useState<Preset>()
  
  const [open, setOpen] = useState('')

  const setOpenAction = (actionName: SetStateAction<string>) => {
    setOpen(actionName)
  }

  const closeAction = () => {
    setOpen('')
  }

  const cols: GridColDef[] = [
    { field: 'robotId', headerName: 'ロボットID', width: 200 },
    {
      field: 'name',
      headerName: 'ロボット名',
      width: 250,
      valueGetter(params: GridValueFormatterParams) {
        return robots.find((t) => t.id === params.row.robotId)?.name
      },
    },
    {
      field: 'tenantName',
      headerName: '所属テナント名',
      width: 200,
      valueGetter(params: GridValueFormatterParams) {
        return tenants.find((t) => t.id === params.row.tenantId)?.name;
      }
    },
    {
      field: 'kind',
      headerName: 'モード名',
      width: 150,
      valueGetter(params: GridValueFormatterParams) {
        return KindNameConv[params.row.kind as keyof KindName]
      }
    },
    {
      field: 'eventSettings',
      headerName: '設定値',
      width: 200,
      flex: 1,
      valueGetter( {row} ) {
        let cell = ''
        const robot = robots.find((t) => t.id === row.robotId)
        const compositionEventSettings = camelizedParser<JsonComposition>(robot?.robotCompositions.jsonData as string).ios.eventSettings
        const eventSettings = camelizedParser(row.eventSettings) as Array<EventSetting>
        eventSettings.forEach(eventSetting => {
          const module = compositionEventSettings.find((t) => t.module === eventSetting.module)
          eventSetting.items?.forEach(item => {
            cell += item.settingItem + ':' + ((module?.notificate || module?.module === 'face') ? item.notificate : item.status) + '\n'
          })
        });
        return cell
      }
    },
  ]
  
  return (
    <>
      <TableComponent
        loading={presetsStatus === 'loading'}
        createForm={{
          formTitle: '構成情報作成',
          FormRows: [
            {
              name: 'eventSettings'
            }
          ]
        }}
        getRowId={(row) => row.tenantId + '&' + row.robotId + '&' + row.kind}
        rows={presets}
        columns={cols}
        pageSize={10}
        onSelection={([row]) => {
          setPresets(row as Preset)
        }}
        open={open}
        onOpen={setOpenAction}
        actions={[
          {
            name: 'editPresets',
            buttonText: '編集',
            color: 'primary',
            disabled: !selectedPreset,
            dialogComponent:  <EditPreset
                                onSubmit={async (preset) => {
                                  const result = (await dispatch(updatePresetsAsync([preset,preset['robotId'],preset['tenantId']]))).payload as Preset[]
                                  if (result != null) {
                                    const newPreset = result.find((t) => t.tenantId === selectedPreset?.tenantId && t.robotId === selectedPreset?.robotId && t.kind === selectedPreset?.kind)
                                    if (newPreset) {
                                      setPresets(newPreset)
                                    }
                                    return true
                                  } else {
                                    return false
                                  }
                                }}
                                onClose={closeAction}
                                preset={selectedPreset}
                                robot={robots.find((t) => t.id === selectedPreset?.robotId)}
                              />
          }
      ]}
    />
    </>
  )
}

export default PresetsPage
