import { Button, Card } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { MuiVirtualizedTable } from '../../components/VirtualizedTable'
import { fetchLogs } from './logsApi'
import {
  Log,
  LogsSearchConv,
  LogsSearchParams,
} from './logsTypes'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

type Data = Log & {
  id: number
}

type Props = {
  searchParams: LogsSearchParams
  onBack?: () => void
}

const LogsTable: React.FC<Props> = ({
  searchParams: _searchParams,
  onBack,
}) => {
  const [rows, setRows] = useState<Data[]>([])
  const [loading, setLoading] = useState(true)
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [fetchLock, setFetchLock] = useState(false)
  const searchParams = LogsSearchConv(_searchParams)

  useEffect(() => {
    ;(async () => {
      // if(search) {
      setFetchLock(true)
      const data = await fetchLogs(searchParams)
      const rows = data.data.map((d, i) => ({ id: i, ...d }))
      setRows(rows)
      setLoading(false)
      setNextToken(data.nextToken)
      let nextToken = data.nextToken
      while(rows.length < 100 && nextToken) {
        const nextData = await fetchLogs({ ...searchParams, nextToken })
        rows.push(...nextData.data.map((d, i) => ({ id: i, ...d })))
        nextToken = nextData.nextToken
        setNextToken(nextData.nextToken)
      }
      setRows([...rows])
      setFetchLock(false)
      // }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchParams])

  const onScrollToBottom = async () => {
    if (fetchLock || !nextToken) return
    setFetchLock(true)
    const nextData = await fetchLogs({ ...searchParams, nextToken })
    setRows([
      ...rows,
      ...nextData.data.map((d, i) => ({ id: rows.length + i, ...d })),
    ])
    setNextToken(nextData.nextToken)
    setFetchLock(false)
  }

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
      >
        検索条件変更
      </Button>
      <Card style={{ marginTop: 20, height: 700 }} elevation={2}>
        <MuiVirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          onScrollToBottom={onScrollToBottom}
          loading={loading}
          completed={!nextToken}
        />
      </Card>
    </div>
  )
}

export default LogsTable
