import React from 'react'
import {
  Drawer,
  DrawerProps,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
  createStyles,
  Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import csp from '../assets/csp_icon_180.png'
import { menu } from '../routes'
import { useSelector } from 'react-redux'
import { selectCurrentRoleType, selectUserName, signOutAsync } from '../features/signin/signinSlice'
import { useAppDispatch } from '../app/hooks'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    menuItem: {
      textDecoration: 'none',
      color: 'black',
    },
    menuText: {
      textAlign: 'center',
      textDecoration: 'none',
    },
    userName: {
      margin: '10px auto',
      padding: '10px',
    },
    drawerIcon: {
      maxWidth: '30%',
      margin: '10px auto',
    },
    spacer: {
      flexGrow: 1
    },
    logoutButton: {
      width: 200,
      margin: '10px auto'
    }
  }),
)

const LeftDrawer: React.FC<DrawerProps> = (props) => {
  const userName = useSelector(selectUserName)
  const role = useSelector(selectCurrentRoleType)
  const classes = useStyles()
  const dispatch = useAppDispatch()

  return (
    <Drawer {...props} className={classes.root}>
      <img src={csp} alt="" className={classes.drawerIcon} />
      <Paper className={classes.userName}>ユーザー名 : {userName}</Paper>
      <List>
        {menu.filter(({acceptRoles}) => acceptRoles == null || acceptRoles?.includes((role ?? 'guard') as typeof acceptRoles[number])).map(({ title, path }, i) => (
          <React.Fragment key={i}>
            <Divider />
            <Link to={path} className={classes.menuItem}>
              <ListItem button>
                <ListItemText
                  primary={title}
                  className={classes.menuText}
                />
              </ListItem>
            </Link>
          </React.Fragment>
        ))}
        <Divider />
      </List>
      <div className={classes.spacer} />
      <Button
        variant="contained"
        className={classes.logoutButton}
        onClick={() => {
          dispatch(signOutAsync())
        }}
      >ログアウト</Button>
    </Drawer>
  )
}

export default LeftDrawer
