import React, { useState } from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import { selectTenants } from '../features/tenants/tenantsSlice'
import {
  createRobotAsync,
  deleteRobotAsync,
  selectRobots,
  selectRobotsStatus,
} from '../features/robots/robotsSlice'
import { selectCompositions } from '../features/compositions/compositionsSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import type { RobotCreateRequest } from '../features/robots/robotsTypes'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { Button } from '@material-ui/core'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter, max255Validator, max50Validator, pathValidator } from '../features/utils'
import {
  clearRobotStatus,
  exportRobotSetting,
  importRobotSetting,
} from '../features/robots/robotsApi'
import { selectFacesApplyInfo } from '../features/faces/facesSlice'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import { indicatorColor } from '../app/constants'
import FaceList from '../features/faces/FaceList'

// const useStyles = makeStyles((theme) => ({
//   rowActionButton: {
//     width: 30
//   }
// })
// )

const faceIndicatorMap = {
  'undefined': {
    icon: <DotIcon htmlColor={indicatorColor.none} />,
    text: '適用外',
  },
  'true': {
    icon: <DotIcon htmlColor={indicatorColor.ok} />,
    text: '適用済',
  },
  'false': {
    icon: <DotIcon htmlColor={indicatorColor.warn} />,
    text: '適用待ち',
  },
  'error': {
    icon: <DotIcon htmlColor={indicatorColor.error} />,
    text: '適用エラー',
  },
}
const greengrassVersions = [
  {
    id: 1,
    name: 'Greengrass V1'
  },
  {
    id: 2,
    name: 'Greengrass V2'
  }
]

const RobotPage: React.FC = () => {
  // const classes = useStyles()
  const tenants = useAppSelector(selectTenants)
  const robotsStatus = useAppSelector(selectRobotsStatus)
  const compositions = useAppSelector(selectCompositions)
  const robots = useAppSelector(selectRobots)
  const facesApplyInfo = useAppSelector(selectFacesApplyInfo)
  const [openFace, setOpenFace] = useState<string | null>(null)

  const dispatch = useAppDispatch()

  const cols: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: '名前', width: 250 },
    {
      field: 'robotCompositions',
      headerName: '構成情報名',
      width: 200,
      renderCell({ value }) {
        return (value as any)['name']
      },
    },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
      // valueGetter({value}) {
      //   return tenants.find((t) => t.id === value)?.name ?? value
      // },
    },
    { field: 'remarks', headerName: '備考', minWidth: 300, flex: 1 },
    {
      field: '_setting',
      headerName: '設定',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }) {
        return (
          <>
            <Button
              title="設定エクスポート"
              onClick={() => {
                exportRobotSetting(row.id, row.tenantId)
              }}
            >
              <GetAppIcon />
            </Button>
            <Button
              title="設定インポート"
              onClick={() => {
                importRobotSetting(row.id, row.tenantId)
              }}
            >
              <PublishIcon />
            </Button>
          </>
        )
      },
    },
    {
      field: '_faceApplyInfo',
      headerName: '顔認証適用状況',
      width: 135,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }) {

        const applyInfo = facesApplyInfo.find((i) => i.robotId === row.id)
        const applyInfoFaceList = applyInfo ? JSON.parse(String(applyInfo?.faceList)) : null;
        const successStr = applyInfoFaceList?.error_list?.length > 0 ? 'error' : String(applyInfo?.success) as keyof typeof faceIndicatorMap;

      // 以下のコメント、上がトラブルなく動作しているようなら消してください。
      /*
        //var successStr = String(applyInfo?.success) as keyof typeof faceIndicatorMap

  
        if (applyInfo != null){
          const test = JSON.parse(String(applyInfo?.faceList))
          if (test != null) {
            if ('error_list' in test) {
              if ((test['error_list'].length) > 0){
                successStr = 'error'
              }
            }
          }
        }
        */
        
        return (
          <>
            <Button startIcon={faceIndicatorMap[successStr].icon} onClick={() => {
              setOpenFace(row.id)
            }}>{faceIndicatorMap[successStr].text}</Button>
            {applyInfo && <FaceList open={row.id === openFace} onClose={() => {
              setOpenFace(null)
            }} robotName={row.name} faceList={applyInfo?.faceList}/>}
          </>
        )
      },
    },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
    {
      field: '_clearStatus',
      headerName: 'ステータスのクリア',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }) {
        return (
          <Button
            title="ステータスのクリア"
            onClick={async () => {
              if (
                await confirm(
                  'ステータスクリア',
                  `${
                    robots.find((r) => r.id === row.id)?.name ?? row.id
                  }のステータスをクリアします。\nロボットが動作中でないことを確認してください。`,
                )
              ) {
                clearRobotStatus(row.id, row.tenantId)
              }
            }}
          >
            <RotateLeftIcon />
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <TableComponent
        loading={robotsStatus === 'loading'}
        createForm={{
          formTitle: 'ロボット作成',
          FormRows: [
            {
              name: 'robotId',
              label: 'ロボットID',
              validators: [
                pathValidator,
                {
                  re: /^.{0,20}$/,
                  helperText: '20文字以下である必要があります',
                }
              ],
            },
            {
              name: 'name',
              label: 'ロボット名',
              validators: [
                max50Validator,
              ]
            },
            {
              type: 'select',
              name: 'compositionId',
              label: '構成情報',
              items: compositions.map(({ name, compositionId: value }) => ({
                name,
                value,
              })),
            },
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants.map(({ name, id: value }) => ({ name, value })),
            },
            {
              type: 'select',
              name: 'greengrassVersion',
              label: 'AWS IoT Greengrassバージョン',
              items: greengrassVersions.map(({ name, id: value }) => ({ name, value })),
            },
            {
              name: 'remarks',
              required: false,
              label: '備考',
              validators: [
                max255Validator,
              ]
            },
          ],
        }}
        onSubmit={async (values) => {
          const { tenantId, ...robot } = values
          dispatch(
            createRobotAsync([robot as RobotCreateRequest, tenantId as string]),
          )
          return true
        }}
        onDelete={async ([id]) => {
          const robot = robots.find((r) => r.id === id)
          if (!robot) return
          if (
            await confirm(
              'ロボット削除',
              `本当に${robot.name}を削除してもよろしいですか?`,
            )
          ) {
            dispatch(deleteRobotAsync([id as string, robot.tenantId]))
          }
        }}
        rows={robots}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default RobotPage
