import { GridLocaleText } from "@material-ui/data-grid"

export const days = {
  'sun' :'日', 
  'mon' :'月', 
  'tue' :'火', 
  'wed' :'水', 
  'thu' :'木', 
  'fri' :'金', 
  'sat' :'土',
}

export const zeroTime = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)

export const localeText: Partial<GridLocaleText> = {
  columnMenuUnsort: 'ソート解除',
  columnMenuSortAsc: '昇順',
  columnMenuSortDesc: '降順',
  columnMenuFilter: '絞り込み',
  columnMenuHideColumn: '列非表示',
  columnMenuShowColumns: '表示列を選択',

  filterOperatorContains: '次を含む',
  filterPanelOperatorAnd: 'かつ',
  filterPanelOperatorOr: 'または',
  filterOperatorEndsWith: '次で終わる',
  filterOperatorStartsWith: '次で始まる',
  filterOperatorIsEmpty: '空',
  filterOperatorIs: 'である',
  filterOperatorEquals: 'と一致',
  filterOperatorIsNotEmpty: '空でない',
  filterOperatorBefore: '以前',
  filterOperatorAfter: '以降',

  footerTotalRows: '件',
  MuiTablePagination: {
    labelRowsPerPage: 'ページごとの行数',
  },
  footerTotalVisibleRows: (v, t) => `${v} / ${t}`,
  footerRowSelected: (c) => `${c} 件選択`,
}

export const smWidth = 800

export const indicatorColor = {
  none: '#aaaaaa',
  ok: '#34d300',
  error: '#ff0000',
  warn: '#f5bd00',
}
