import React, { useEffect, useState } from 'react'
import md5 from 'md5'
import { CircularProgress } from '@material-ui/core'

type SsecImgProps = {
  src: string
  ssecKey?: string
} & React.ImgHTMLAttributes<any>

const SsecImg: React.FC<SsecImgProps> = (props) => {
  const { src, ssecKey, ...imgProps } = props
  const [image, setImage] = useState('')

  useEffect(() => {
    ;(async () => {
      const rawKey = window.atob(ssecKey ?? '')
      const byteKey = new Uint8Array(rawKey.length).map((_, i) =>
        rawKey.charCodeAt(i),
      )
      const hashedKey = md5(byteKey, { asString: true })
      const imgRes = await fetch(src, {
        headers: {
          'x-amz-server-side-encryption-customer-algorithm': 'AES256',
          'x-amz-server-side-encryption-customer-key-MD5': btoa(hashedKey),
          'x-amz-server-side-encryption-customer-key': ssecKey ?? '',
        },
      }).catch((e) => {
        console.error(e)
        return void 0
      })
      if(!imgRes) return
      const image = URL.createObjectURL(await imgRes.blob())
      setImage(image)
    })()
  }, [src, ssecKey])

  // eslint-disable-next-line jsx-a11y/alt-text
  return image.length > 0 ? <img {...imgProps} src={image} /> : <CircularProgress style={{ color: '#AAA' }} />
}

export default SsecImg
