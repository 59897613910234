import { RequestOptions } from "./utils"
import { apiEndpoint, camelizedParser, post } from "./utils"
import { alert } from './dialogs/alert'
import { loading } from "./dialogs/loading"
import { RoleType } from "./users/usersTypes"

export type LoginResponseData = {
  token: string
  userName: string
  roleList: {
    tenant: string
    role: RoleType
  }[]
  isSystemAdmin: boolean
}

type LoginResponse = {
  status: string
  data: LoginResponseData
}

/**
 * APIを叩くためのクラス  
 * トークンを内部に持つ
 */
class Api {
  private token?: string
  
  /**
   * APIサーバーにログイン
   * @param idToken cognitoから取得したidトークン
   * @returns ログインしたユーザーの情報
   */
  async login(idToken: string): Promise<LoginResponseData> {
    const res = await post('/maintenance/login', {
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        id: idToken,
      }),
    })
    const text = await res.text()
    const json: LoginResponse = camelizedParser(text)
    this.token = json.data.token
    if (process.env.NODE_ENV==='development') {
      console.log(json.data)
    }
    return json.data
  }

  /**
   * @param path APIのパス
   * @param options リクエスト
   * @returns 実行結果
   */
  async get(path: string, options?: RequestOptions): Promise<Response> {
    let { headers, ...opt } = options ?? {}
    if (!headers) headers = {}
    if (path.startsWith('http')) {
      const idx = path.indexOf('/', 8)
      path = path.substring(idx)
    }
    const res = await fetch(encodeURI(apiEndpoint + path), {
      method: 'get',
      headers: {
        authorization: 'Bearer ' + this.token ?? '',
        ...headers,
      },
      ...opt,
    })
    if (!res.ok) {
      const json = await res.json()
      alert('エラー', JSON.stringify(json, null, '  '))
      throw json
    }
    return res
  }
  
  /**
   * @param path APIのパス
   * @param options リクエスト
   * @param noHeaders 自動で付加するヘッダ無効フラグ
   * @returns 実行結果
   */
  async post(path: string, options?: RequestOptions, noHeaders: boolean = false): Promise<Response> {
    let { headers, ...opt }: {headers?: any} = options ?? {}
    if (!headers) headers = {}
    if (path.startsWith('http')) {
      const idx = path.indexOf('/', 8)
      path = path.substring(idx)
    }
    headers['authorization'] = 'Bearer ' + this.token ?? ''
    if (!('content-type' in headers) && !noHeaders) {
      headers['content-type'] = 'application/json'
    }
    const res = await fetch(encodeURI(apiEndpoint + path), {
      method: 'post',
      headers,
      ...opt,
    })
    return res
  }

  /**
   * @param path APIのパス
   * @param options リクエスト
   * @param noHeaders 自動で付加するヘッダ無効フラグ
   * @returns 実行結果
   */
  put(path: string, options?: RequestOptions, noHeaders: boolean = false): Promise<Response> {
    let { headers, ...opt }: {headers?: any} = options ?? {}
    if (!headers) headers = {}
    if (path.startsWith('http')) {
      const idx = path.indexOf('/', 8)
      path = path.substring(idx)
    }
    headers['authorization'] = 'Bearer ' + this.token ?? ''
    if (!('content-type' in headers) && !noHeaders) {
      headers['content-type'] = 'application/json'
    }
    return fetch(encodeURI(apiEndpoint + path), {
      method: 'put',
      headers,
      ...opt,
    })
  }
  
  /**
   * @param path APIのパス
   * @param options リクエスト
   * @returns 実行結果
   */
  async delete(path: string, options?: RequestOptions, noHeaders: boolean = false): Promise<Response> {
    let { headers, ...opt }: {headers?: any} = options ?? {}
    if (!headers) headers = {}
    if (path.startsWith('http')) {
      const idx = path.indexOf('/', 8)
      path = path.substring(idx)
    }
    headers['authorization'] = 'Bearer ' + this.token ?? ''
    if (!('content-type' in headers) && !noHeaders) {
      headers['content-type'] = 'application/json'
    }
    const res = await fetch(encodeURI(apiEndpoint + path), {
      method: 'delete',
      headers,
      ...opt,
    })
    const json = await res.json()
    if (!res.ok && json.error?.message) {
      alert('エラー', json.error.message)
    }  
    return res
  }

  /**
   * APIサーバーからファイルをダウンロード
   * @param path ダウンロードファイルのパス
   * @param filename ダウンロードに使うファイル名
   */
  async download(path: string, filename: string | null = null) {
    const closeLoading = loading('', 'エクスポート中…')
    const res = await this.get(path)
    const url = window.URL.createObjectURL(await res.blob())
    const a = document.createElement('a')
    a.href = url
    if (filename) {
      a.download = filename
    } else {
      const disposition = res.headers.get('content-disposition')
      if(disposition) {
        const params = disposition.split(';').map(s => s.split('='))
        const filename = params.find(p => p[0] === 'filename')?.[1]
        if(filename) a.download = decodeURI(filename)
      }
    }
    document.body.appendChild(a)
    a.click()
    closeLoading()
    a.remove()
  }

  /**
   * リンクからファイルをダウンロード
   * @param url ダウンロードリンク
   */
  async openDownloadLink(url: string) {
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.rel = 'noreferrer'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
}

export const api = new Api()
