import type { GridColDef } from '@material-ui/data-grid'
import React, { SetStateAction, useState } from 'react'
import TableComponent from '../components/TableComponent'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { FormValue } from '../components/Form/FormRows'
import {
  deleteMapAsync,
  fetchMapFromRobotAsync,
  fetchMapsAsync,
  selectMaps,
  selectMapsStatus,
  uploadMapAsync,
} from '../features/maps/mapsSlice'
import { selectTenantsForSelect } from '../features/tenants/tenantsSlice'
import { confirm } from '../features/dialogs/confirm'
import Form from '../components/Form'
import { selectRobots } from '../features/robots/robotsSlice'
import { Typography } from '@material-ui/core'
import { cellTimeGetter } from '../features/utils'

const MapsPage: React.FC = () => {
  const maps = useAppSelector(selectMaps)
  const mapsStatus = useAppSelector(selectMapsStatus)
  const tenants = useAppSelector(selectTenantsForSelect)
  const robots = useAppSelector(selectRobots)
  const [openAction, setOpenAction] = useState('')
  const dispatch = useAppDispatch()

  const cols: GridColDef[] = [
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
      // align: 'right',
      // valueGetter({ value }) {
      //   return tenants.find((t) => t.value === value)?.name ?? value
      // },
    },
    { field: 'fileName', headerName: '地図画像名', width: 200 },
    { field: 'path', headerName: '地図画像パス', width: 500 },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
  ]

  const onSubmit = async (values: FormValue) => {
    const file = values['mapFile']
    const tenantId = values['tenantId'] as string
    if (file instanceof File) {
      const duplicate = maps.find((m) => m.path.endsWith(file.name)) ?? false
      if (
        duplicate &&
        !(await confirm(
          '注意',
          '重複ファイルがあります。上書きしてもよろしいですか？',
        ))
      ) {
        return false
      } else {
        await dispatch(uploadMapAsync([file, tenantId]))
        return true
      }
    }
  }

  const onOpenChanged = (actionName: SetStateAction<string>) => {
    setOpenAction(actionName)
  }

  const onActionCancel = () => {
    setOpenAction('')
  }

  return (
    <>
      <TableComponent
        loading={mapsStatus === 'loading'}
        createForm={{
          formTitle: '地図画像登録',
          createText: '送信',
          FormRows: [
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
            },
            {
              type: 'file',
              accept: 'image/png',
              name: 'mapFile',
              label: '地図画像ファイル',
            },
          ],
        }}
        onSubmit={onSubmit}
        getRowId={(row) => {
          return row.fileName + '&' + row.tenantId
        }}
        open={openAction}
        onOpen={onOpenChanged}
        onDelete={async ([id]) => {
          const [fileName, tenantId] = (id as string).split('&')
          if (
            await confirm(
              '地図画像削除',
              `${
                tenants.find((t) => t.value === tenantId)?.name ?? tenantId
              } / ${fileName} を削除してもよろしいですか?`,
            )
          ) {
            dispatch(deleteMapAsync([fileName, tenantId]))
          }
        }}
        actions={[
          {
            name: 'downloadFromRobot',
            buttonText: 'ロボットから追加',
            dialogComponent: (
              <Form
                formTitle="ロボットから地図追加"
                FormRows={[
                  {
                    type: 'select',
                    name: 'tenantId',
                    label: 'テナント',
                    items: tenants,
                  },
                  {
                    name: 'robot',
                    type: 'select',
                    label: 'ロボット',
                    items: robots,
                    itemGetter(item) {
                      return {
                        name: item.name,
                        value: item.id,
                      }
                    },
                    filter(values, items) {
                      return items.filter(
                        (i) => i.tenantId === values['tenantId'],
                      )
                    },
                  },
                  {
                    label: '地図画像名',
                    name: 'mapName',
                  },
                ]}
                onCancel={onActionCancel}
                onSubmit={(values) => {
                  dispatch(
                    fetchMapFromRobotAsync([
                      values['mapName'] as string,
                      values['tenantId'] as string,
                      values['robot'] as string,
                    ]),
                  )
                  setTimeout(() => {
                    dispatch(fetchMapsAsync())
                  }, 30000)
                  setOpenAction('')
                }}
              />
            ),
          },
        ]}
        rows={maps}
        columns={cols}
        pageSize={10}
      />
      <Typography variant="caption" color="textSecondary">
        ※同じファイル名で追加すると上書きされます。
      </Typography>
    </>
  )
}

export default MapsPage
