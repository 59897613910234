import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createUser, deleteUser, fetchUsers } from './usersApi'
import type { User, UserCreateRequest } from './usersTypes'


export interface usersState {
  users: User[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: usersState = {
  users: [],
  status: 'unload',
};

export const fetchUsersAsync = createAsyncThunk(
  'users/fetch',
  async () => {
    const response = await fetchUsers().catch((err) => {
      console.log('users fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const createUserAsync = createAsyncThunk(
  'users/create',
  async (user: UserCreateRequest) => {
    if (await createUser(user)) {
      return (await fetchUsers())?.data
    } else {
      return null
    }
  }
)

export const deleteUserAsync = createAsyncThunk(
  'users/delete',
  async (userName: string) => {
    await deleteUser(userName)
    return (await fetchUsers())?.data
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersAsync.fulfilled, (state: usersState, action) => {
        state.status = 'loaded';
        state.users = action.payload
      })
      .addCase(createUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createUserAsync.fulfilled, (state: usersState, action) => {
        state.status = 'loaded'
        state.users = action.payload ?? state.users
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteUserAsync.fulfilled, (state: usersState, action) => {
        state.status = 'loaded'
        state.users = action.payload ?? state.users
      })
  },
});


export const selectUsers = (state: RootState) => state.users.users;
export const selectUsersStatus = (state: RootState) => state.users.status
export const selectUsersForShow = (state: RootState) => state.users.users.map(({roleList, ...u}) => ({...u, role: roleList[0]?.role, tenants: [...new Set(roleList.map(r => r.tenant))]}))

export default usersSlice.reducer;
