import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createFloor, deleteFloor, fetchFloors } from './floorsApi'
import type { CreateFloorRequest, Floor } from './floorsTypes'


export interface floorsState {
  floors: Floor[]
  status: 'unload' | 'loading' | 'loaded';
}

const initialState: floorsState = {
  floors: [],
  status: 'unload',
};

export const fetchFloorsAsync = createAsyncThunk(
  'floors/fetch',
  async () => {
    const response = await fetchFloors().catch((err) => {
      console.log('floors fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return []
    }
  }
);

export const createFloorAsync = createAsyncThunk('floors/create', async ([floor, tenantId]: [CreateFloorRequest, string]) => {
  await createFloor(floor, tenantId)
  return (await fetchFloors())?.data
})

export const deleteFloorAsync = createAsyncThunk('floors/delete', async ([floorId, tenantId]: [string, string]) => {
  await deleteFloor(floorId, tenantId)
  return (await fetchFloors())?.data
})

export const floorsSlice = createSlice({
  name: 'floors',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFloorsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFloorsAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.floors = action.payload
      })
      .addCase(createFloorAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createFloorAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.floors = action.payload ?? state.floors
      })
      .addCase(deleteFloorAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteFloorAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.floors = action.payload ?? state.floors
      })
  },
});


export const selectFloors = (state: RootState) => state.floors.floors;
export const selectFloorsStatus = (state: RootState) => state.floors.status
export const selectFloorsForSelect = (state: RootState) => state.floors.floors.map(({name, id: value}) => ({name, value}));

export default floorsSlice.reducer;
