import type { GridColDef, GridRowId } from '@material-ui/data-grid'
import React from 'react'
import TableComponent from '../components/TableComponent'
import {
  selectsounds,
  uploadSoundAsync,
  selectsoundsStatus,
  deleteSoundAsync,
} from '../features/sounds/soundsSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { FormValue } from '../components/Form/FormRows'
import { selectTenantsForSelect } from '../features/tenants/tenantsSlice'
import { confirm } from '../features/dialogs/confirm'
import { cellTimeGetter } from '../features/utils'
import { Typography } from '@material-ui/core'

const SoundPage: React.FC = () => {
  const sounds = useAppSelector(selectsounds)
  const tenants = useAppSelector(selectTenantsForSelect)
  const soundsStatus = useAppSelector(selectsoundsStatus)
  const dispatch = useAppDispatch()

  const cols: GridColDef[] = [
    {
      field: 'tenantId',
      headerName: 'テナント名',
      width: 150,
      // align: 'right',
      valueGetter({ value }) {
        return tenants.find((t) => t.value === value)?.name ?? value
      },
    },
    { field: 'fileName', headerName: '音声名', width: 500 },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
  ]

  const onSubmit = async (values: FormValue) => {
    const file = values['soundFile']
    const tenantId = values['tenantId'] as string
    if (file instanceof File) {
      const duplicate =
        sounds.find(
          (s) => s.tenantId === tenantId && s.fileName === file.name,
        ) ?? false
      if (
        duplicate &&
        !(await confirm(
          '注意',
          '重複ファイルがあります。上書きしてもよろしいですか？',
        ))
      ) {
        return false
      } else {
        await dispatch(uploadSoundAsync([file, tenantId]))
        return true
      }
    }
  }

  const onDelete = async ([id]: GridRowId[]) => {
    const [fileName, tenantId] = (id as string).split('&')
    if (
      await confirm(
        '音声素材削除',
        `${
          tenants.find((t) => t.value === tenantId)?.name ?? tenantId
        } / ${fileName} を削除してもよろしいですか?`,
      )
    ) {
      dispatch(deleteSoundAsync([fileName, tenantId]))
    }
  }

  return (
    <>
      <TableComponent
        loading={soundsStatus === 'loading'}
        createForm={{
          formTitle: '音声登録',
          createText: '送信',
          FormRows: [
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
            },
            {
              type: 'file',
              accept: 'audio/*',
              name: 'soundFile',
              label: '音声ファイル',
            },
          ],
        }}
        onSubmit={onSubmit}
        onDelete={onDelete}
        getRowId={(row) => {
          return row.fileName + '&' + row.tenantId
        }}
        rows={sounds}
        columns={cols}
        pageSize={10}
      />
      <Typography variant="caption" color="textSecondary">
        ※同じファイル名で追加すると上書きされます。
      </Typography>
    </>
  )
}

export default SoundPage
