import React, { useEffect, useState } from 'react'
import csp from '../assets/csp_icon_180.png'

import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  createStyles,
  makeStyles,
  Dialog,
  CircularProgress,
} from '@material-ui/core'

import {
  selectSigninStatus,
  signinAsync,
  trySigninWithRefreshTokenAsync,
} from '../features/signin/signinSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { getLastAuthUser } from '../features/utils'
import { smWidth } from '../app/constants'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplate: `"icon header" 150px
                     "main main" 1fr / 150px 1fr`,
      backgroundColor: '#EEE',
      width: '100vw',
      height: '100vh',
    },
    logo: {
      gridArea: 'icon',
      margin: 'auto',
      maxWidth: '100px',
    },
    header: {
      gridArea: 'header',
      display: 'flex',
      height: headerHeight,
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginFormContainer: {
      gridArea: 'main',
      height: '100%',
      padding: '0px 10px',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginContent: {
      maxWidth: '400px',
    },
    loginButton: {
      marginTop: 10,
    },
    laoding: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 10px 5px',
      '&>*': {
        margin: '5px',
      },
    },
    invalidCard: {
      width: '400px',
      padding: '10px',
      marginBottom: '20px',
      backgroundColor: '#F88',
    },
    title: {},
    [`@media (max-width: ${smWidth}px)`]: {
      root: {
        gridTemplate: `"icon" 70px
                       "header" auto
                       "main " 1fr / auto`,
        backgroundColor: '#EEE',
      },
      logo: {
        margin: '10px auto',
      },
      title: {
        fontSize: '7vw',
      },
    },
  }),
)

const headerHeight = '150px'

const LoginPage: React.FC<{}> = (props) => {
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const changUsernameHaldler = (e: any) => setUsername(e.target.value)
  const changPasswordHandler = (e: any) => setPassword(e.target.value)
  const loading = useAppSelector(selectSigninStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const lastAuthUser = getLastAuthUser()
    if (lastAuthUser) {
      setUsername(lastAuthUser)
      dispatch(trySigninWithRefreshTokenAsync())
    }
  }, [dispatch])

  const signIn = (ev: any) => {
    ev.preventDefault?.()
    dispatch(
      signinAsync({
        password,
        userId: username,
      }),
    )
  }

  return (
    <div className={classes.root}>
      <Dialog open={loading === 'loading'} classes={{ paper: classes.laoding }}>
        <CircularProgress />
        <Typography>ログイン試行中...</Typography>
      </Dialog>
      <img src={csp} alt="" className={classes.logo} />
      <header className={classes.header}>
        <Typography
          variant="h3"
          color="textSecondary"
          className={classes.title}
        >
          CSPARX管理ログイン画面
        </Typography>
      </header>
      <div className={classes.loginFormContainer}>
        {loading === 'failed' && (
          <Card classes={{ root: classes.invalidCard }}>
            <Typography>ユーザーIDまたはパスワードが間違っています</Typography>
          </Card>
        )}
        <Card>
          <CardContent className={classes.loginContent}>
            <form>
              <TextField
                label="Username"
                variant="outlined"
                required
                margin="normal"
                value={username}
                onChange={changUsernameHaldler}
                fullWidth
              />
              <TextField
                label="Password"
                variant="outlined"
                required
                margin="normal"
                type="password"
                value={password}
                onChange={changPasswordHandler}
                fullWidth
              />
              {/* <Link to="/"> */}
              <Button
                variant="contained"
                type="submit"
                className={classes.loginButton}
                color="primary"
                onClick={signIn}
                disabled={username.length <= 0 || password.length <= 0}
                fullWidth
              >
                ログイン
              </Button>
            </form>
            {/* </Link> */}
          </CardContent>
          {/* <CardActions></CardActions> */}
        </Card>
      </div>
    </div>
  )
}

export default LoginPage
