import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useRef } from 'react'
import {
  GuardEvent,
  guardEventStatuses,
  guardEventTags,
  guardEventTypes,
} from './eventsTypes'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
// import StopIcon from '@material-ui/icons/Stop'
import { useState } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../app/hooks'
import { selectRobots } from '../robots/robotsSlice'

type Props = {
  event: GuardEvent
  className?: string
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },
  cardImage: {
    maxWidth: 500,
    width: '50%',
  },
  cardBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    flexGrow: 1,
  },
  cardHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  cardTitle: {
    flexShrink: 0,
    flexGrow: 1,
  },
  cardStatus: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '3px 0px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tagContainer: {
    margin: '3px 5px',
  },
  tag: {
    backgroundColor: '#CCC',
    margin: '5px 0px',
    padding: '5px 10px',
    borderRadius: '100vw',
  },
}))

export const EventCard: React.FC<Props> = ({ event, className }) => {
  const classes = useStyles()
  const imgRef = useRef<HTMLImageElement>(null)
  const robots = useAppSelector(selectRobots)

  const [playVideo, setPlayVideo] = useState(false)
  const [openImage, setOpenImage] = useState(false)

  const handleVideoPlay = () => {
    setPlayVideo(true)
  }

  const handleVideoClose = () => {
    setPlayVideo(false)
  }

  const handleImageClick = () => {
    setOpenImage(true)
  }

  const handleImageClose = () => {
    setOpenImage(false)
  }

  const eventType = guardEventTypes[event.kind]

  return (
    <Card className={clsx(classes.card, className)}>
      <CardMedia
        component="img"
        className={classes.cardImage}
        image={eventType.eventImage ? eventType.eventImage : event.resourceUrl}
        ref={imgRef}
        onClick={handleImageClick}
        loading="lazy"
      />
      <Box className={classes.cardBox}>
        <CardContent className={classes.boxContainer}>
          <div className={classes.cardHeader}>
            <Typography variant="h5" className={classes.cardTitle}>
              {new Date(event.date * 1000).toLocaleString()}
            </Typography>
            <div className={classes.cardStatus}>
              {guardEventStatuses[event.status]?.icon ?? event.status}
              <span style={{ width: 10 }}></span>
              <Typography variant="body2" align="right">
                {robots.find((r) => r.id === event.robotId)?.name ??
                  event.robotId}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            {event.contactUserName && (
              <Typography variant="body1">
                対応者 : {event.contactUserName ?? event.contactUserId}
              </Typography>
            )}
            <span style={{width: '5px'}}></span>
            {event.tag && (
              <div className={classes.tagContainer}>
                <span className={classes.tag} style={{backgroundColor: guardEventTags[event.tag].color}}>{guardEventTags[event.tag].text}</span>
              </div>
            )}
          </div>
          <Typography variant="body1">
            {eventType.text}検知
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {
            event.preMovieUrl &&
            <Button
              variant="contained"
              startIcon={<PlayArrowIcon />}
              onClick={handleVideoPlay}
            >
              プリ動画再生
            </Button>
          }
          <Dialog open={playVideo} onClose={handleVideoClose}>
            <video src={event.preMovieUrl} muted autoPlay controls/>
          </Dialog>
          <Dialog open={openImage} onClose={handleImageClose}>
            {
              event.kind !== 'sound' ?
                <img src={event.resourceUrl} alt="イベント検知画像"/>
                :
                <audio src={event.resourceUrl} controls />
            }
          </Dialog>
        </CardActions>
      </Box>
    </Card>
  )
}
