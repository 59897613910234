import { api } from '../Api'
import { camelizedParser, kebabizedStringifier } from '../utils'
import type { MapsResponse } from './mapsTypes'

export async function fetchMaps(): Promise<MapsResponse | undefined> {
  const res = await api.get('/maintenance/map/list', {})
  const json = await res.text()
  return camelizedParser(json)
}

export async function uploadMap(mapImage: File, tenantId: string) {
  const formData = new FormData()
  formData.append('upload_file', mapImage)
  await api.post(`/maintenance/tenant/${tenantId}/admin/map-upload`, {
    body: formData
  }, true)
}

export async function fetchMapFromRobot(mapName: string, tenantId: string, robotId: string) {
  await api.post(`/maintenance/tenant/${tenantId}/admin/robot/${robotId}/drive-map-upload`, {
    body: kebabizedStringifier({mapName})
  })
}

export async function deleteMap(fileName: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/map-delete`, {
    body: kebabizedStringifier({fileName})
  })
}
