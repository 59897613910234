import { api } from '../Api'
import { apiEndpoint, camelizedParser, kebabize } from '../utils'
import {
  OperationLog,
  OperationSearchOptions,
  OperationsResponse,
} from './operationsTypes'

const queryInjector = (url: URL, options: OperationSearchOptions): URL => {
  Object.entries(options).forEach(([k,v]) => {
    if(v == null || v === '' || k === 'tenantId' || k === 'robotId') return
    if (v instanceof Date) {
      url.searchParams.append(kebabize(k), Math.floor(v.getTime() / 1000) + '')
    } else {
      url.searchParams.append(kebabize(k), v + '')
    }
  })
  return url
}

export async function fetchOperations(options: OperationSearchOptions): Promise<OperationLog[]> {
  const url = new window.URL(`/maintenance/tenant/${options.tenantId}/admin/robot/${options.robotId}/operation-list`, apiEndpoint)
  queryInjector(url, options)
  const res = await api.get(url.pathname + url.search)
  const json = camelizedParser<OperationsResponse>(await res.text())

  return json.data ?? []
}
